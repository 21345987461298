import React, { Component } from "react";
import WelcomeAccordion from "./WelcomeAccordian";
import WhatsNewAccordion from "./whatsnewaccordion";
import logger from "../services/logService";
import constValues from "../services/constants";
import authValues from "../components/auth/authService";
import Loading from "../components/common/loading";

class Landing extends Component {
  state = {
    loading: true,
    childrenLength: 2,
    childrenLoaded: 0,
  };

  async componentDidMount() {
    const { userEmailAddress, accessGroup, clientName } =
    await authValues.getAuthValues();

    //Log values once the user lands on landing page
    logger.log(
      userEmailAddress,
      clientName,
      accessGroup,
      constValues.emptyString,
      constValues.emptyString,
      constValues.clientEventOccurred,
      constValues.descriptionValues.LandingPage,
      constValues.currentUrl,
      constValues.clientStatusCodes.LandingPage,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString
    );
  }

  //function to display a loading icon until all the reponses are received
  onChildLoad = () => {
    //Using arrow functions instead of binding
    this.setState({ childrenLoaded: this.state.childrenLoaded + 1 }, () => {
      if (this.state.childrenLoaded === this.state.childrenLength) {
        this.setState({ loading: false });
      }
    });
  };

  //render the welcome message and whatsnew component (if any)
  render() {
    return (
      <React.Fragment>
        <div id="outer-container">
          {this.state.loading && <Loading />}
          <React.Fragment>
            <h4 className="welcomeMsg">Welcome To Argus Advisory Online Portal</h4>
            <WelcomeAccordion
              onChildLoad={this.onChildLoad}
              loading={this.state.loading}
            />

            <WhatsNewAccordion
              onChildLoad={this.onChildLoad}
              loading={this.state.loading}
            />
            <br />
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  }
}

export default Landing;
