import React, { Component } from "react";
import SideNavBar from "./sidenavpage";
import axios from "axios";
import config from "../config.json";
import http from "../services/httpService";
import logger from "../services/logService";
import constValues from "../services/constants";
import authValues from "../components/auth/authService";
import { startTableauDataDownload } from "./datadownloadfunctions";
import { isSheetsAvailableforDownload } from "./datadownloadfunctions";
import ErrorPage from "../components/error";
import "../components/css/dashboard.css";
import Loading from "../components/common/loading";
import Navbar from "../components/common/navbar";
import { Auth } from "aws-amplify";

class DashBoardDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: "",
      accessgroup: "",
      loading: true,
      datadownload: "",
      btnDisabled: false,
      tableauServerName: "",
      loadDashboard: false,
      loadTableauFile: false,
      bgColor: "#00466c",
    };

    this.downloadTableauJsFile = this.downloadTableauJsFile.bind(this);
  }

  async componentDidMount() {
    const { userEmailAddress, accessGroup, clientName } =
      await authValues.getAuthValues();
    let queryString = window.location.search;
    let querystring = new URLSearchParams(queryString).get("query");
    let appid = [];
    appid.push(querystring);

    const sesssionDetails = await Auth.currentSession();
    let accessToken = sesssionDetails.getAccessToken().getJwtToken();
    axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

    let reqParams = {
      appid: appid,
      accessGroup: accessGroup,
      userEmailAddress: userEmailAddress,
      clientName,
    };

    //lambda call to get the Tableau ticket and the dashboard url
    const { data: token } = await http.post(
      http.apiEndPoint + "getTicket",
      reqParams
    );

    this.setState({
      token: token,
      tableauServerName: token.tableauServerUrl,
      loading: false,
    });

    if (this.state.tableauServerName != "") {
      this.downloadTableauJsFile(this.state.tableauServerName);
    }

    //lambda call to get the data download config file if any
    const { data: datadownload } = await http.post(
      http.apiEndPoint + "datadownload",
      reqParams
    );

    this.setState({ datadownload: datadownload });

    // function to handle click based on the buttons clicked
    this.downloadTableau = (vFileType) => {
      if (vFileType == "PDF") {
        this.setState({ btnDisabled: true, bgColor: "grey" });

        setTimeout(
          () => this.setState({ btnDisabled: false, bgColor: "#00466c" }),
          5000
        );

        tableauViz.showExportPDFDialog();

        logger.log(
          userEmailAddress,
          clientName,
          accessGroup,
          constValues.appType.Tableau,
          constValues.emptyString,
          constValues.clientEventOccurred,
          constValues.descriptionValues.PDFButton +
            " : " +
            tableauViz.getWorkbook().getActiveSheet().getName(),
          constValues.currentUrl,
          constValues.clientStatusCodes.PDFButton,
          constValues.emptyString,
          token.dashboardTitle,
          token.dashboardUrl,
          constValues.emptyString,
          constValues.emptyString,
          constValues.emptyString,
          constValues.emptyString
        );
      }
      if (vFileType == "IMAGE") {
        this.setState({ btnDisabled: true, bgColor: "grey" });

        setTimeout(
          () => this.setState({ btnDisabled: false, bgColor: "#00466c" }),
          5000
        );

        tableauViz.showExportImageDialog();
        logger.log(
          userEmailAddress,
          clientName,
          accessGroup,
          constValues.appType.Tableau,
          constValues.emptyString,
          constValues.clientEventOccurred,
          constValues.descriptionValues.ImageButton +
            " : " +
            tableauViz.getWorkbook().getActiveSheet().getName(),
          constValues.currentUrl,
          constValues.clientStatusCodes.ImageButton,
          constValues.emptyString,
          token.dashboardTitle,
          token.dashboardUrl,
          constValues.emptyString,
          constValues.emptyString,
          constValues.emptyString,
          constValues.emptyString
        );
      }
      //future use
      // if (vFileType == "PPT") {
      //   setTimeout(showValidButtonForActiveSheet, 5000);
      //   //tableauViz.showExportPowerPointDialog();
      //   //tableauViz.showExportCrossTabDialog();
      //   tableauViz
      //     .exportCrossTabToExcel
      //     // tableauViz.getWorkbook().getActiveSheet().getName()
      //     ();

      //   // logger.log(
      //   //   userEmailAddress,
      //   //   clientName,
      //   //   accessGroup,
      //   //   constValues.appType.Tableau,
      //   //   constValues.emptyString,
      //   //   constValues.clientEventOccurred,
      //   //   constValues.descriptionValues.PPTButton +
      //   //     " : " +
      //   //     tableauViz.getWorkbook().getActiveSheet().getName(),
      //   //   constValues.currentUrl,
      //   //   constValues.clientStatusCodes.PPTButton,
      //   //   constValues.emptyString,
      //   //   constValues.emptyString,
      //   //   constValues.emptyString,
      //   //   constValues.emptyString,
      //   //   constValues.emptyString,
      //   //   constValues.emptyString,
      //   //   constValues.emptyString
      //   // );
      // }
      if (vFileType == "DATA") {
        this.setState({ btnDisabled: true, bgColor: "grey" });

        setTimeout(
          () => this.setState({ btnDisabled: false, bgColor: "#00466c" }),
          8000
        );
        startTableauDataDownload(
          tableauViz,
          datadownload,
          datadownload.GstrSheetNameKeyword,
          datadownload.GFileNameAddition
        );

        logger.log(
          userEmailAddress,
          clientName,
          accessGroup,
          constValues.appType.Tableau,
          constValues.emptyString,
          constValues.clientEventOccurred,
          constValues.descriptionValues.DataButton +
            " : " +
            tableauViz.getWorkbook().getActiveSheet().getName(),
          constValues.currentUrl,
          constValues.clientStatusCodes.DataButton,
          constValues.emptyString,
          token.dashboardTitle,
          token.dashboardUrl,
          constValues.emptyString,
          constValues.emptyString,
          constValues.emptyString,
          constValues.emptyString
        );
      }
      if (vFileType == "TRENDED") {
        this.setState({ btnDisabled: true, bgColor: "grey" });

        setTimeout(
          () => this.setState({ btnDisabled: false, bgColor: "#00466c" }),
          8000
        );
        startTableauDataDownload(
          tableauViz,
          datadownload,
          datadownload.GstrTrendedSheetNameKeyword,
          datadownload.GFileNameAddition + datadownload.GAppendString
        );

        logger.log(
          userEmailAddress,
          clientName,
          accessGroup,
          constValues.appType.Tableau,
          constValues.emptyString,
          constValues.clientEventOccurred,
          constValues.descriptionValues.TrendedButton +
            " : " +
            tableauViz.getWorkbook().getActiveSheet().getName(),
          constValues.currentUrl,
          constValues.clientStatusCodes.TrendedButton,
          constValues.emptyString,
          token.dashboardTitle,
          token.dashboardUrl,
          constValues.emptyString,
          constValues.emptyString,
          constValues.emptyString,
          constValues.emptyString
        );
      }

      return false;
    };

    logger.log(
      userEmailAddress,
      clientName,
      accessGroup,
      constValues.appType.Tableau,
      constValues.emptyString,
      constValues.clientEventOccurred,
      constValues.descriptionValues.TableauDashboardPage + appid,
      constValues.currentUrl,
      constValues.clientStatusCodes.TableauDashboardPage,
      constValues.emptyString,
      token.dashboardTitle,
      token.dashboardUrl,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString
    );

    if (this.state.loadTableauFile) {
      if (window.tableauSoftware === undefined) {
        this.componentDidMount();
      } else {
        this.setState({ loadDashboard: true });
        var tableauViz;
        var tableauActiveSheet;

        if (document.getElementById("dashboardurl").value == "") {
          return;
        }

        var placeholderDiv = document.getElementById("frmReport");
        var url = document.getElementById("dashboardurl").value;

        var options = {
          onFirstInteractive: function () {
            tableauActiveSheet = tableauViz
              .getWorkbook()
              .getActiveSheet()
              .getName();

            showValidButtonForActiveSheet();

            logger.log(
              userEmailAddress,
              clientName,
              accessGroup,
              constValues.appType.Tableau,
              constValues.emptyString,
              constValues.clientEventOccurred,
              constValues.descriptionValues.DashboardSheet + tableauActiveSheet,
              constValues.currentUrl,
              constValues.clientStatusCodes.TableauDashboardSheetClicked,
              constValues.emptyString,
              token.dashboardTitle,
              token.dashboardUrl,
              constValues.emptyString,
              constValues.emptyString,
              constValues.emptyString,
              constValues.emptyString
            );
          },
        };

        tableauViz = new window.tableauSoftware.Viz(
          placeholderDiv,
          url,
          options
        );

        tableauViz.addEventListener("tabSwitch", function (tabs) {
          showValidButtonForActiveSheet();
          tableauActiveSheet = tabs.getNewSheetName();
          logger.log(
            userEmailAddress,
            clientName,
            accessGroup,
            constValues.appType.Tableau,
            constValues.emptyString,
            constValues.clientEventOccurred,
            constValues.descriptionValues.DashboardSheet + tableauActiveSheet,
            constValues.currentUrl,
            constValues.clientStatusCodes.TableauDashboardSheetClicked,
            constValues.emptyString,
            token.dashboardTitle,
            token.dashboardUrl,
            constValues.emptyString,
            constValues.emptyString,
            constValues.emptyString,
            constValues.emptyString
          );
        });

        function showValidButtonForActiveSheet() {
          disableButtons();
          if(document.getElementById("PDFOpen") !== null) { document.getElementById("PDFOpen").classList.remove("disabledbtn")};
         if(document.getElementById("ImageOpen") !== null) {document.getElementById("ImageOpen").classList.remove("disabledbtn")};
         if(document.getElementById("DataOpen") !== null){document.getElementById("DataOpen").classList.remove("disabledbtn")};
          if(document.getElementById("TrendedDataOpen") !== null){ document.getElementById("TrendedDataOpen").classList.remove("disabledbtn")};
          if(document.getElementById("customBtnOpen") !== null){document.getElementById("customBtnOpen").classList.remove("disabledbtn")};

          if (
            !(
              typeof datadownload.GstrSheetNameKeyword == "undefined" ||
              datadownload.GstrSheetNameKeyword == null ||
              datadownload.GstrSheetNameKeyword == ""
            )
          ) {
            if (
              isSheetsAvailableforDownload(
                tableauViz,
                datadownload.GstrSheetNameKeyword
              )
            ) {
          if(document.getElementById("DataOpen") !== null){document.getElementById("DataOpen").classList.remove("disabledbtn")};
            } else {
              if(document.getElementById("PDFOpen") !== null) {document.getElementById("PDFOpen").classList.add("disabledbtn");}
          
            }
          }

          if (
            !(
              typeof datadownload.GstrTrendedSheetNameKeyword == "undefined" ||
              datadownload.GstrTrendedSheetNameKeyword == null ||
              datadownload.GstrTrendedSheetNameKeyword == ""
            )
          ) {
            //Enable Trended data download button
            if (
              isSheetsAvailableforDownload(
                tableauViz,
                datadownload.GstrTrendedSheetNameKeyword
              )
            ) {
              if(document.getElementById("TrendedDataOpen") !== null) {document.getElementById("TrendedDataOpen").classList.remove("disabledbtn");}
          
            } else {
              if(document.getElementById("TrendedDataOpen") !== null){ document.getElementById("TrendedDataOpen").classList.add("disabledbtn");}
         
            }
          }
        }

        function disableButtons() {
          if( document.getElementById("PDFOpen") !== null) {document.getElementById("PDFOpen").classList.add("disabledbtn");}
          if(document.getElementById("ImageOpen") !==null){ document.getElementById("ImageOpen").classList.add("disabledbtn");}
         if(document.getElementById("DataOpen") !== null) {document.getElementById("DataOpen").classList.add("disabledbtn");}
          if(document.getElementById("TrendedDataOpen") !== null) { document.getElementById("TrendedDataOpen").classList.add("disabledbtn");}
         if(document.getElementById("customBtnOpen") !== null) {document.getElementById("customBtnOpen").classList.add("disabledbtn");}
          
        }
      }
    }

    //function to download appropriate js file from the Tableau sevrer
  }

  downloadTableauJsFile(serverName) {
    const script = document.createElement("script");
    script.src = serverName + config.tableauServerJavaScript;
    script.async = true;
    document.head.appendChild(script);
    this.setState({ loadTableauFile: true });
  }
  onChildLoad = () => {};

  // function to Display erorr message or load the dashboard based on the respnse from lambda
  dashboardDisplay() {
    if (this.state.token.status == "Error") {
      //added the input element here to avoid null values passed to hidden variable
      return (
        <React.Fragment>
          
          <ErrorPage />
          <input type="hidden" value="" id="dashboardurl"></input>
        </React.Fragment>
      );
    } else {
      return (
        <input
          type="hidden"
          value={this.state.token.reqDashboardUrl}
          id="dashboardurl"
        ></input>
      );
    }
  }

  //function to display the data download buttons appropriately
  //button information will be received as comma seperated values (array) from the backend
  //buttons will be rendered in order in which the values have been received

  datadownloadButtonsDisplay() {
    let htmlSpace = <span>&nbsp;</span>;
    let dataDownloadText = <span>Data Download : </span>;

    let datadownloadspan = <span className="datadownloadspan"></span>;
    if (!this.state.token.getButtonsForDataDownload) {
      return;
    }

    let buttonsToRender = JSON.parse(
      this.state.token.getButtonsForDataDownload
    );
    let renderHtmlButtons = [];
    renderHtmlButtons.push(htmlSpace);

    for (let i = 0; i < buttonsToRender.length; ++i) {
      let onClickValue = buttonsToRender[i].onclick;
      const button = (
        <button
          id={buttonsToRender[i].id}
          className="disabledbtn btn-active btn-hide"
          onClick={() => this.downloadTableau(onClickValue)}
          disabled={this.state.btnDisabled}
          style={{ backgroundColor: this.state.bgColor }}
        >
          {buttonsToRender[i].value}
        </button>
      );

      renderHtmlButtons.push(htmlSpace);
      renderHtmlButtons.push(htmlSpace);
      renderHtmlButtons.push(button);
    }

    //only if there is any button to be displayed
    if (renderHtmlButtons.length > 1) {
      renderHtmlButtons.unshift(dataDownloadText);
      renderHtmlButtons.unshift(datadownloadspan);

      //renderHtmlButtons.unshift(htmlSpace);
    }

    return (
      <React.Fragment>
        {renderHtmlButtons}
        <br></br>
      </React.Fragment>
    );
  }

  //render dashboard and data download buttons
  render() {
    if (this.state.token.status !== "Error") {
      if (this.state.loadDashboard) {
        return (
          <React.Fragment>

              <div style={{ "padding-top": "5px" }}>
                {this.datadownloadButtonsDisplay()}
              </div>

              <div
                id="frmReport"
                className="check"
                style={{ width: "100%", height: "100%" }}
              >
                {this.dashboardDisplay()}
              </div>
          </React.Fragment>
        );
      } else {
        return <Loading />;
      }
    } else {
      return (
        <React.Fragment>
          <ErrorPage />
          <input type="hidden" value="" id="dashboardurl"></input>
        </React.Fragment>
      );
    }
  }
}

export default DashBoardDisplay;
