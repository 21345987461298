import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import axios from "axios";
import config from "../config.json";
import http from "../services/httpService";
import authValues from "../components/auth/authService";
import "../components/css/welcomeaccordian.css";
import { Auth } from "aws-amplify";

class WelcomeAccordion extends Component {
  constructor() {
    super();
    this.state = {
      maintenanceMessages: [],
      isLoaded: false,
    };
  }

  async handleSubmit() {
    const { userEmailAddress, accessGroup } = await authValues.getAuthValues();
    const sesssionDetails = await Auth.currentSession();
    let accessToken = sesssionDetails.getAccessToken().getJwtToken();
    axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

    //complete file path for constructing the presigned url
    let filePath = config.welcomeGuidePath;
    let reqParams = {
      filePath: filePath,
      accessGroup: accessGroup,
      userEmailAddress: userEmailAddress,
    };
    const res = await http.post(
      http.apiEndPoint + "downloadgenericfiles",
      reqParams
    );

    if (res.data == "Error") {
      alert(
        "Something went wrong! Please try again!. If problem continues please contact our Support Team"
      );
    } else {
      window.open(res.data);
    }
  }

  async componentDidMount() {
    const { userEmailAddress, accessGroup, clientName } =
      await authValues.getAuthValues();
    const reqParams = {
      userEmailAddress,
      accessGroup,
      clientName,
    };

    const sesssionDetails = await Auth.currentSession();
    let accessToken = sesssionDetails.getAccessToken().getJwtToken();
    axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

    const { data: maintenanceMessages } = await http.post(
      http.apiEndPoint + "getMaintenenceNotification",
      reqParams
    );
    this.setState({ maintenanceMessages });
    this.setState({ isLoaded: true }, () => this.props.onChildLoad());
    //console.log(maintenanceMessages);
  }

  //Render static welcome message
  //Render maintainence notification if any

  render() {
    return (
      <React.Fragment>
        {this.state.isLoaded && !this.props.loading && (
          <div className="welcomeaccordian">
            <Accordion
              allowMultipleExpanded={true}
              allowZeroExpanded={true}
              preExpanded={["WelcomeMessage"]}
            >
              <AccordionItem uuid="WelcomeMessage">
                <AccordionItemHeading>
                  <AccordionItemButton>Welcome Message</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Welcome to Argus Advisory Online Portal - your one stop shop for deep
                    insights into your data.
                    <br /><br />
                    Argus Advisory Online Portal is a web-based platform allowing you to
                    access Argus Advisory's unique, industry-leading benchmarking and
                    analytics tools. If you have any issues accessing the portal
                    contact our support team.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              {this.state.maintenanceMessages.map((maintenanceMessage) => (
                <AccordionItem
                  expanded={true}
                  key={maintenanceMessage.notificationid}
                >
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      {maintenanceMessage.notificationheader}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: maintenanceMessage.notificationmessage,
                      }}
                    ></div>
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default WelcomeAccordion;
