import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import authValues from "../auth/authService";
import constValues from "../../services/constants";
import logger from "../../services/logService";
import http from "../../services/httpService";
import "../css/navbar.css";
import Avatar from "react-avatar";
import { Auth } from "aws-amplify";
import userContext from "../auth/AuthContext";


  class NavBar extends Component {
    static contextType = userContext;
    state = {
      authenticated: null,
      userinfo: null,
      token: "",
      businessUnitLogo: "",
      Username: "",
    };
    // Code of logout timer
    // lastLogin
    lastActivity
    // popUpResponseTimer
    
    
    // checks the inactivity time of user
    checkLastActivityDiff=async()=>{
      clearTimeout(this.lastActivity);
      clearTimeout(this.popUpResponseTimer);

      this.lastActivity=setTimeout(()=>{
        this.props.popUp(true)
        
      },constValues.loginExpiry.logoutTimer*60000);

      // this.popUpResponseTimer = setTimeout(()=>{this.logout()},10000)

        // Code might be needed in future
        //  const timeDiff = new Date()
        //  console.log(timeDiff,"current minute");
        //  console.log(timeDiff - this.lastActivity,"This is diff");
        //  setTimeout( this.props.popUp(true),60000)
        //  setInterval(this.props.popUp(true),60000)
        //  if(timeDiff - this.lastActivity>=constValues.loginExpiry.inActivityThreshold){
        //     this.props.popUp(true)
        //     console.log("logged out"); 
        //     this.logout();
        //  }else{
        //   this.lastActivity = new Date();
        //  }    
    }
    
    // code might be needed in future
    // checks the timer elapsed between the lastLogin
    // checkLastLogin = async()=>{
    //   console.log("Lastlogin check invoked");
    //   const timeDiff = new Date();
    //   console.log(timeDiff-this.lastLogin);
    //   if(timeDiff - this.lastLogin>=constValues.loginExpiry.lastLoginThreshold){
    //     console.log("logged out");
    //     this.logout();
    //  }    
    //    setTimeout(this.checkLastLogin,30000);
    // }    




    checkAuthentication = async () => {
      let authenticated = null;
      try {
        await Auth.currentAuthenticatedUser();
        authenticated = true;
      } catch (err) {
        authenticated = false;
      }
      if (authenticated !== this.state.authenticated) {
        this.setState({ authenticated });
      }
    };

    //function to get BU logo dynamically
    async getLogo() {
      const { userEmailAddress, accessGroup, clientName, userName } = await authValues.getAuthValues();
      this.setState({ userName });
  
      const sesssionDetails = await Auth.currentSession();
      let accessToken = sesssionDetails.getAccessToken().getJwtToken();
      axios.defaults.headers.common = {
        Authorization: `Bearer ${accessToken}`,
      };

      let reqParams = {
        accessGroup: accessGroup,
        userEmailAddress: userEmailAddress,
        clientName: clientName,
      };

      const { data: logo } = await http.post(
        http.apiEndPoint + "getBusinessUnitLogo",
        reqParams
      );

      this.setState({ businessUnitLogo: logo[0].businessunitlogo });
    }

    async componentDidMount() {
      this.checkAuthentication();
	  Auth.currentSession().then((res) => {
        const accessToken = res.accessToken;
        if (accessToken === undefined) {
          this.componentDidMount();
        } else {
          this.setState({ accessToken });
          this.getLogo();
          this.lastLogin = new Date();
          this.lastActivity = new Date();
          this.checkLastActivityDiff();
          // clearTimeout(this.lastActivity);
          window.addEventListener('click',this.checkLastActivityDiff);
          window.addEventListener('mousemove',this.checkLastActivityDiff)
          window.addEventListener('keydown',this.checkLastActivityDiff);
          window.addEventListener('scroll',this.checkLastActivityDiff);
          // this.checkLastLogin(); 
        }
      });
      
     }

    async componentDidUpdate() {
      this.checkAuthentication();
    }

    //login prop to redirect the user to the landing page
    login = async () => {
      this.context.login();
    };

    // logout prop from OKTA
    // log the action
    //Redirect the user to logout redirect page, show the spinning icon and redirect to argusinformation page
    logout = async () => {
      const { userEmailAddress, accessGroup, clientName } =
        await authValues.getAuthValues();
  
      logger.log(
        userEmailAddress,
        clientName,
        accessGroup,
        constValues.emptyString,
        constValues.emptyString,
        constValues.clientEventOccurred,
        constValues.descriptionValues.LoggedOut,
        constValues.currentUrl,
        constValues.clientStatusCodes.LoggedOut,
        constValues.emptyString,
        constValues.emptyString,
        constValues.emptyString,
        constValues.emptyString,
        constValues.emptyString,
        constValues.emptyString,
        constValues.emptyString
      );
  
      window.removeEventListener("click", this.checkLastActivityDiff);
      window.location.href = "/LogoutRedirect";
    };

    //this function returns login or logout button based on auth status
    checkLoginStatus() {
      const loginStatus = this.state.authenticated ? (
        <div>
          <Link className="btn btn-lg" id="logout" to="/Landing">
            Home
          </Link>
          <Link className="" to="/Contact">
            <button className="btn btn-lg" id="logout">
              Contact
            </button>
          </Link>
          <Link className="btn btn-lg" id="logout" onClick={this.logout}>
            Logout
          </Link>
        </div>
      ) : (
        <div>
          <Link className="" to="/Contact">
            <button className="btn btn-lg" id="logout">
              Contact
            </button>
          </Link>
          <Link className="btn btn-lg" id="login" onClick={this.login}>
            Login
          </Link>
        </div>
      );

      return loginStatus;
    }

    render() {
      //do not render the component on the following routes
      if (
        window.location.pathname == "/LogoutRedirect"
      ) {
        return <React.Fragment></React.Fragment>;
      }
      return (
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/Landing">
              <div>
                <img src={this.state.businessUnitLogo} className="logosize" />
                {this.state.displayClientLogo != null && (
                  <img
                    src={this.state.displayClientLogo}
                    className="logosize"
                  />
                )}
              </div>
            </Link>
            <ul className="navbar-nav mr-auto"></ul>
            {this.checkLoginStatus()}
          </div>

          <div onClick={this.handleClick}>
            <Avatar name={this.state.userName} round={true} size="50" />
          </div>
        </nav>
      );
    }
  }
  
  export default NavBar;