const descriptionValues = {
  LandingPage: "User entered landing page",
  TableauDashboardPage: "User entered dashboards display page",
  DashboardPage: "User entered dashbaoard page",
  AnalyzerPage: "User entered Analyzer page",
  Contact: "User entered contactus page",
  DashboardSheet: "Dashboard sheet clicked :",
  ReportsPage: "User entered Reports Page",
  SSMSPage: "User entered SSMS Page",
  RedirectApplicationsPage: "USerEntered RedirectApplications Page",
  AccessDenied: "User sent to access denied page and logged out",
  UnExpectedError: "Unexpected Error occurred",
  PDFButton: "PDF download button clicked",
  ImageButton: "Image button clicked",
  PPTButton: "PPT download button clicked",
  DataButton: "Data download button clicked",
  TrendedButton: "Trended download button clicked",
  LoggedOut: "User Logged out",
  PBIDashboardPage: "User entered Power BI dashboards display page",
};

const clientStatusCodes = {
  LandingPage: "701",
  DashboardPage: "702",
  TableauDashboardPage: "703",
  TableauDashboardSheetClicked: "704",
  ReportsPage: "705",
  AnalyzerPage: "707",
  UnexpectedError: "708",
  SSMSPage: "709",
  RedirectApplications: "710",
  LoggedOut: "777",
  PDFButton: "711",
  ImageButton: "712",
  PPTButton: "713",
  DataButton: "714",
  TrendedButton: "715",
  PBIDashboardPage: "716",
};

const appType = {
  Tableau: "Tableau",
  Analyzer: "Analyzer",
  Reports: "Reports",
  SSMS: "SSMS",
  PowerBI: "PowerBI",
};


const TableauDashboard = "tabapp";
const PBIDashboard = "pbiapp";


const emptyString = "";

const currentUrl = window.location.href;

const clientEventOccurred = new Date().toString();


// loginExpiry constants 
const loginExpiry = {
  logoutTimer : 29
}

export default {
  descriptionValues,
  clientEventOccurred,
  appType,
  emptyString,
  currentUrl,
  clientStatusCodes,
  TableauDashboard,
  PBIDashboard,
  loginExpiry
};
