import React, { Component } from "react";
import { Auth } from "aws-amplify";

  class AuthCheck extends Component {
    state = { authenticated: null };
  
    //check for the user authstate if
    checkAuthentication = async () => {
      let authenticated = null;
      try {
        await Auth.currentAuthenticatedUser();
        authenticated = true;
      } catch (err) {
        authenticated = false;
      }
      if (authenticated !== this.state.authenticated) {
        this.setState({ authenticated });
        Auth.federatedSignIn({ customProvider: process.env.REACT_APP_FEDERATED_IDP });
      }
    };

    async componentDidMount() {
      this.checkAuthentication();
    }

    async componentDidUpdate() {
      this.checkAuthentication();
    }

    render() {
      return <React.Fragment></React.Fragment>;
    }
  }
  
  export default AuthCheck;
