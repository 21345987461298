import React, { Component } from "react";
import "../components/css/error.css";
import configvalues from "../config.json";

//component to render accessdenied message if the account set up has some issues.
//user will be logged out with appropriate error message on the screen
class AccessDenied extends Component {
  render() {
    return (
      <React.Fragment>
        <div id="divwrap">
          <div className="errordiv">
            <h4>
              {configvalues.accessDeniedMessage}
              <a
                href={`mailto:${configvalues.supportemailaddress}?subject=Account set up issue`}
              >
                {" "}
                {`${configvalues.supportemailaddress}`}
              </a>
              <br />
            </h4>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AccessDenied;
