import * as alasql from "alasql";
alasql["private"].externalXlsxLib = require("xlsx");

var tableauViz;
var GFileNameAddition = "";

var GdictParamaterValueForStore = {};

// Sheet name keyword ==> All the sheets having this value as part of its name will be downloaded. If the value of GstrSheetNameKeyword is "*****" then all sheets will be downloaded.
// If this variable is undefined then no sheets will be downloaded.
var GstrSheetNameKeyword = "";
// Sheet name keyword ==> All the sheets having this value as part of its name will be downloaded on the Trends data download button click.
// If the value of GstrTrendedSheetNameKeyword is "*****" then all sheets will be downloaded. If this variable is undefined then no sheets will be downloaded.
var GstrTrendedSheetNameKeyword = "";

//Any Column Names in the below dictionary will not be downloaded. If this variable is undefined or if the dashboard name is not found then all the columns in the sheets will be downloaded.
var GdictColumnsToIgnore = {};

//Measure Name : Parameter Name ==> Given measure name is replaced with currently selected value of the given parameter name for each sheet
var GdictMeasure_Param = {};

//Find string : Replace string based on each dashboard. If the dashboard name is not found then no column names are replaced.
var GdictFind_Replace = {};

//The below columns will be added with filter values for each sheet if it does not exist.
//Filter Name : New Column Name
var GdictAdditionalColumnsFromFilter = {};
//Any customized Column Names in the below dictionary will be used to check if the value in this column is null or empty. If this cell value is null or empty then it will not download the entire row data. If it has some value then it will be downloaded.
var GdictRowsToIgnoreBasedOnColumnValues = {};
var GdictAdditionalColumnsFromParameter;
var GActiveDashboardName;
var GExcelFileName;
var GDownloadSheets;
var GSheetsSummaryData;
var GdictOrderOfSheet;
var GCurrentIndex;
var GdictAppliedFilterValueOfWSheet;
var strSheetName;
var GdictWBookParameters;

export function startTableauDataDownload(
  tableauViz,
  datadownload,
  varDataDownloadType,
  varFileNameAddition
) {
  GActiveDashboardName = tableauViz.getWorkbook().getActiveSheet().getName();

  //console.log(datadownload);
  GFileNameAddition = datadownload.GFileNameAddition;

  GstrSheetNameKeyword = datadownload.GstrSheetNameKeyword;

  GstrTrendedSheetNameKeyword = datadownload.GstrTrendedSheetNameKeyword;

  GdictColumnsToIgnore = datadownload.GdictColumnsToIgnore;

  GdictMeasure_Param = datadownload.GdictMeasure_Param;

  GdictFind_Replace = datadownload.GdictFind_Replace;

  GdictAdditionalColumnsFromFilter =
    datadownload.GdictAdditionalColumnsFromFilter;

  GdictRowsToIgnoreBasedOnColumnValues =
    datadownload.GdictRowsToIgnoreBasedOnColumnValues;

  GdictAdditionalColumnsFromParameter =
    datadownload.GdictAdditionalColumnsFromParameter;

  //console.log(datadownload);
  var activeDashboard = tableauViz.getWorkbook().getActiveSheet();
  GActiveDashboardName = activeDashboard.getName();

  //console.log("Dashboard Name = " + GActiveDashboardName);
  GExcelFileName =
    escapeSpecialCharacters(GActiveDashboardName + varFileNameAddition) +
    ".xlsx";
  var sheetsOfDashboard = activeDashboard.getWorksheets();

  tableauViz
    .getWorkbook()
    .getParametersAsync()
    .always(function (paramData) {
      storeParameter(paramData);
    });

  var dictDownloadableSheets = getSheetsInfoForDataDownload(
    sheetsOfDashboard,
    varDataDownloadType
  );

  //If there are no sheets to download then return
  var intCount = Object.keys(dictDownloadableSheets).length;
  if (typeof intCount == "undefined" || intCount <= 0) {
    //console.log("No sheets on this dashboard are allowed to download");
    //To show only the buttons as per the available download options
    // showValidButtonForActiveSheet();
    return false;
  }

  GDownloadSheets = new Array(intCount); //To store the sheet names with some properties
  GSheetsSummaryData = new Array(intCount); //To store summary data of each sheet
  GdictAppliedFilterValueOfWSheet = {}; //To store all the parameters from different sheets of a dashboard
  GdictOrderOfSheet = {}; //To store sheet index and its order.
  GCurrentIndex = 0;
  intCount = 0;
  for (var intSheetIndex in dictDownloadableSheets) {
    strSheetName = dictDownloadableSheets[intSheetIndex];
    GDownloadSheets[intCount] = { sheetid: strSheetName, header: true };
    GdictOrderOfSheet[intSheetIndex] = intCount;
    makeTableauAsyncCalls(intSheetIndex, sheetsOfDashboard[intSheetIndex]);
    intCount++;
  }
}

/*
    Initiates Asyn calls to Tableau server for Filter information and Summary data
*/
function makeTableauAsyncCalls(varSheetIndex, objWSheet) {
  //Gets the filters applied to each sheet
  objWSheet.getFiltersAsync().always(function (fData) {
    var intCurrSheetIndex = varSheetIndex;
    //console.log("In Filter Async" + intCurrSheetIndex);
    storeFilter(intCurrSheetIndex, fData);
  });
  //Gets the summary data of each sheet
  objWSheet.getSummaryDataAsync().always(function (rData) {
    var intCurrSheetIndex = varSheetIndex;
    //console.log("In summary Async" + intCurrSheetIndex);
    storeSummary(intCurrSheetIndex, rData);
  });
}

/*
    Callback function
    Saves the filter information for each sheet in the current dashboard
*/
function storeFilter(varSheetIndex, varFData) {
  var varDictforSheet = {};
  var varDictAdditionalColumns =
    GdictAdditionalColumnsFromFilter[GActiveDashboardName];
  if (
    typeof varDictAdditionalColumns == "undefined" ||
    varDictAdditionalColumns == null ||
    varDictAdditionalColumns == ""
  )
    return;
  if (typeof varFData == "undefined" || varFData == null || varFData == "")
    return;

  var tempFKey;
  ////console.log("Sheet Name " + GCurrentIndex + "~~" + GDownloadSheets[GCurrentIndex].sheetid + "~~");
  ////console.log("No. of filters:" + varFData.length);
  for (var varFKey in varDictAdditionalColumns) {
    tempFKey = varFKey.trim().toUpperCase();
    for (var i = 0; i < varFData.length; i++) {
      ////console.log("Type == Name == " + varFData[i].getFilterType() + "==" + varFData[i].getFieldName());
      //console.log("length =" + varFData[i].getAppliedValues().length + "==first value is " + varFData[i].getAppliedValues()[0].formattedValue);
      if (
        tempFKey == varFData[i].getFieldName().trim().toUpperCase() &&
        varFData[i].getFilterType() == "categorical"
      ) {
        varDictforSheet[varDictAdditionalColumns[varFKey]] = varFData[
          i
        ].getAppliedValues()[0].formattedValue;
        //console.log("length =" + varFData[i].getAppliedValues().length + "==first value is " + varFData[i].getAppliedValues()[0].formattedValue);
        break;
      }
    }
  }
  GdictAppliedFilterValueOfWSheet[varSheetIndex] = varDictforSheet;
}

/*
    Callback function
    Saves all the parameter information for the current dashboard
*/
function storeParameter(paramData) {
  GdictWBookParameters = {};
  ////console.log("No. of Parameters:" + paramData.length + "\nName : Value\n");
  for (var i = 0; i < paramData.length; i++) {
    GdictWBookParameters[paramData[i].getName()] = paramData[
      i
    ].getCurrentValue().formattedValue;
    ////console.log(paramData[i].getName() + " : " + paramData[i].getCurrentValue().formattedValue);
  }
  // To store additional parameters for the sheet
  //GdictParamaterValueForStore = {};
  if (
    typeof GdictAdditionalColumnsFromParameter == "undefined" ||
    GdictAdditionalColumnsFromParameter == null ||
    GdictAdditionalColumnsFromParameter == ""
  )
    return;
  var varDictAdditionalParamColumns =
    GdictAdditionalColumnsFromParameter[GActiveDashboardName];
  if (
    typeof varDictAdditionalParamColumns == "undefined" ||
    varDictAdditionalParamColumns == null ||
    varDictAdditionalParamColumns == ""
  )
    return;

  for (var varParamKey in varDictAdditionalParamColumns) {
    if (varParamKey in GdictWBookParameters)
      GdictParamaterValueForStore[varDictAdditionalParamColumns[varParamKey]] =
        GdictWBookParameters[varParamKey];
  }
  //console.log("Length of Parameter dictionary is " + Object.keys(GdictWBookParameters).length);
  //console.log("Length of config Parameter is " + Object.keys(GdictMeasure_Para).length);
}

/*
    Callback function
    Saves the summary for each sheet in the current dashboard.
    Initiates to save all the collected data in an excel
*/
function storeSummary(varSheetIndex, receivedData) {
  var arrdictDataDump;
  var dictOneRowData;
  var varTempFieldName, varSummary;

  ///console.log("Sheet Name " + GCurrentIndex + "~~" + GDownloadSheets[GCurrentIndex].sheetid + "~~");
  if (
    typeof receivedData == "undefined" ||
    receivedData == null ||
    receivedData == "" ||
    receivedData.getTotalRowCount() <= 0
  ) {
    console.log(
      "Error in receiving summary data from " +
        GDownloadSheets[varSheetIndex].sheetid +
        "Sheet within " +
        GExcelFileName
    );
    arrdictDataDump = [{ "": "" }];
  } else {
    try {
      varSummary = receivedData.getData();

      //To get the customized Column Names as per Config file.
      var dictColumns = getColumnsInfoForDataDownload(
        receivedData.getColumns()
      );
      //To add more columns from the filters
      var dictAdditionalColumns = getAdditionalColumnsForSheet(
        varSheetIndex,
        dictColumns
      );
      //To add more columns from the Parameters
      for (var varParamKey in GdictParamaterValueForStore)
        dictAdditionalColumns[varParamKey] =
          GdictParamaterValueForStore[varParamKey];

      //To ignore the entire row that has null or empty values in a list of columns pre-defined in Config file
      var dictCheckingColumns = getCheckingColumnsForSheet(dictColumns);

      var boolIgnoreRow = false;
      var intRowCount = 0;
      //console.log("Data length :" + varSummary.length);
      arrdictDataDump = new Array();
      for (var i = 0; i < varSummary.length; i++) {
        dictOneRowData = {};
        //To add the additional columns values of filter/Parameters, that we have determined to add to this sheet.
        for (var varAddCol in dictAdditionalColumns)
          dictOneRowData[varAddCol] = dictAdditionalColumns[varAddCol];

        boolIgnoreRow = false;
        for (var varColumnID in dictColumns) {
          if (
            varColumnID in dictCheckingColumns &&
            isValidValue(varSummary[i][varColumnID].formattedValue.trim()) ==
              false
          ) {
            boolIgnoreRow = true;
            break;
          }
          varTempFieldName = dictColumns[varColumnID];
          dictOneRowData[varTempFieldName] =
            varSummary[i][varColumnID].formattedValue;
          //console.log(i + "::::" + varColumnID + " = " + varTempFieldName + "::::" + varSummary[i][varColumnID].formattedValue);
        }
        if (!boolIgnoreRow) {
          //Store one row data with the row ID
          arrdictDataDump[intRowCount] = dictOneRowData;
          intRowCount++;
        }
      }

      //for (var q = 0; q < arrdictDataDump.length; q++)
      //console.log(arrdictDataDump[q]);
    } catch (err) {
      arrdictDataDump = [{ "": "" }];
      console.log("In error block..." + err);
    }
  } //End of Else

  //Storing each sheets data in a Global array
  GSheetsSummaryData[GdictOrderOfSheet[varSheetIndex]] = arrdictDataDump;
  GCurrentIndex++;

  //Data will be saved in an Excel file, after all the Asynchoronous function calls has returned the summary data.
  if (GCurrentIndex >= GDownloadSheets.length) {
    writeToExcelFile();
    GCurrentIndex = 0;
    //To enable the buttons for the current sheet
    //showValidButtonForActiveSheet();
  }
}

/*
    Gets the list of sheets that are available for download
*/
function getSheetsInfoForDataDownload(
  arrSheetsInDashB,
  strKeywordForDownloadSheet
) {
  var strSheetName;
  var dictSheetsToDownload = {};

  if (typeof strKeywordForDownloadSheet == "undefined")
    return dictSheetsToDownload;

  for (var i = 0; i < arrSheetsInDashB.length; i++) {
    strSheetName = arrSheetsInDashB[i].getName();
    if (
      strKeywordForDownloadSheet == "*****" ||
      strSheetName.search(new RegExp(strKeywordForDownloadSheet, "i")) != -1
    ) {
      strSheetName = escapeSpecialCharacters(strSheetName);
      strSheetName = strSheetName.replace(strKeywordForDownloadSheet, "");
      dictSheetsToDownload[i] = strSheetName;
      //console.log("Sheet " + i + " ||||||" + strSheetName);
    }
  }
  return dictSheetsToDownload;
}

/*
    Gets the list of columns that are available for download of a particular sheet
*/
function getColumnsInfoForDataDownload(varColumns) {
  //This 'for' loop helps to manipulate the columns and its names as per the Config file.
  var dictColumns = {};
  for (var j = 0; j < varColumns.length; j++) {
    if (
      varColumns[j].getIsReferenced() &&
      IsColumnDownloadable(
        GActiveDashboardName,
        varColumns[j].getFieldName(),
        GdictColumnsToIgnore
      )
    ) {
      dictColumns[j] = replaceMeasureNameWithParameterValue(
        GActiveDashboardName,
        varColumns[j].getFieldName(),
        GdictWBookParameters,
        GdictMeasure_Param
      ); //This function returns the FieldName without any change if the Measure-Parameter mapping is not matching
      if (dictColumns[j] == varColumns[j].getFieldName())
        dictColumns[j] = replaceMeasureNameWithCustomizedName(
          GActiveDashboardName,
          dictColumns[j],
          GdictFind_Replace
        );
    }
    //else
    //console.log("Discarded Field Name :" + varColumns[j].getFieldName());
  }
  return dictColumns;
}

/*
    determines if the given columns is available for download or not in a particular dashboard
*/
function IsColumnDownloadable(
  strDBoardName,
  strColumnName,
  dictIgnoreColumnList
) {
  var strUcaseColumnName = strColumnName.trim().toUpperCase();
  if (
    typeof dictIgnoreColumnList == "undefined" ||
    dictIgnoreColumnList == null ||
    dictIgnoreColumnList == ""
  )
    return true;

  var arrIgnoreColumns = dictIgnoreColumnList[strDBoardName];
  if (
    typeof arrIgnoreColumns == "undefined" ||
    arrIgnoreColumns == null ||
    arrIgnoreColumns.length <= 0
  )
    return true;

  for (var i = 0; i < arrIgnoreColumns.length; i++) {
    if (arrIgnoreColumns[i].trim().toUpperCase() == strUcaseColumnName)
      return false;
  }
  return true;
}

/*
    This function replaces a column name with parameter value based on the settings in config file
*/
function replaceMeasureNameWithParameterValue(
  strDBoardName,
  strMeasureName,
  dictWBookParameters,
  varDictMeasureParam
) {
  var strUCaseMeasureName;

  if (
    typeof varDictMeasureParam == "undefined" ||
    varDictMeasureParam == null ||
    varDictMeasureParam == ""
  )
    return strMeasureName;

  //To get the Dictionary for the currently active dashboard.
  var varDictMeasToParamForDBoard = varDictMeasureParam[strDBoardName];
  if (
    typeof varDictMeasToParamForDBoard == "undefined" ||
    varDictMeasToParamForDBoard == null ||
    Object.keys(varDictMeasToParamForDBoard).length <= 0
  )
    return strMeasureName;

  strUCaseMeasureName = strMeasureName.trim().toUpperCase();
  for (var varMKey in varDictMeasToParamForDBoard) {
    if (varMKey.trim().toUpperCase() == strUCaseMeasureName) {
      var strUCaseParamName = varDictMeasToParamForDBoard[varMKey]
        .trim()
        .toUpperCase();
      for (var varParamKey in dictWBookParameters) {
        if (varParamKey.trim().toUpperCase() == strUCaseParamName) {
          //console.log("Found Measure Name : Replaced Param Value ==== " + strMeasureName + " : " + dictWBookParameters[varParamKey])
          //To return the value of the matched Parameter
          return dictWBookParameters[varParamKey];
        }
      }
    }
  }
  return strMeasureName;
}

/*
    This function replaces a column name with customized name as it is provided in the config file
*/
function replaceMeasureNameWithCustomizedName(
  strDBoardName,
  strMeasureName,
  dictFind_ReplaceMeasures
) {
  var strUCaseMeasureName;

  if (
    typeof dictFind_ReplaceMeasures == "undefined" ||
    dictFind_ReplaceMeasures == null ||
    dictFind_ReplaceMeasures == ""
  )
    return strMeasureName;

  //To get the Dictionary for the currently active dashboard.
  var varDictFind_ReplaceMeasuresForDBoard =
    dictFind_ReplaceMeasures[strDBoardName];
  if (
    typeof varDictFind_ReplaceMeasuresForDBoard == "undefined" ||
    varDictFind_ReplaceMeasuresForDBoard == null ||
    Object.keys(varDictFind_ReplaceMeasuresForDBoard).length <= 0
  )
    return strMeasureName;

  strUCaseMeasureName = strMeasureName.trim().toUpperCase();
  for (var varMKey in varDictFind_ReplaceMeasuresForDBoard) {
    if (varMKey.trim().toUpperCase() == strUCaseMeasureName) {
      return varDictFind_ReplaceMeasuresForDBoard[varMKey];
    }
  }
  return strMeasureName;
}

/*
    Gets the list of additional columns and its values for a particular sheet
*/
function getAdditionalColumnsForSheet(varSheetIndex, varDictColumns) {
  var dictAddColumns = {};
  var dictAppliedFilter = GdictAppliedFilterValueOfWSheet[varSheetIndex];

  if (
    typeof dictAppliedFilter == "undefined" ||
    dictAppliedFilter == null ||
    dictAppliedFilter == ""
  )
    return dictAddColumns;

  var boolMatch;
  var tempAFKey;
  for (var varAFKey in dictAppliedFilter) {
    boolMatch = false;
    tempAFKey = varAFKey.trim().toUpperCase();
    for (var varColID in varDictColumns) {
      if (tempAFKey == varDictColumns[varColID].trim().toUpperCase()) {
        boolMatch = true;
        break;
      }
    }
    if (!boolMatch) dictAddColumns[varAFKey] = dictAppliedFilter[varAFKey];
  }
  return dictAddColumns;
}

/*
    To save all the data received from Tableau server to an excel file
*/
function writeToExcelFile() {
  //To save the Excel file
  // alert("i am in write to excel file");

  // window.saveFile = function () {

  var res = alasql('SELECT * INTO XLSX("' + GExcelFileName + '",?) FROM ?', [
    GDownloadSheets,
    GSheetsSummaryData,
  ]);

  //alasql('SELECT * INTO XLS("newData.xls",?) FROM ?',[mystyle,items]);
  //};
}

//window.saveFile();
//console.log("after call");

/*
    Replace all special characters in file/sheet names with underscore '_'
*/
function escapeSpecialCharacters(varStrName) {
  //console.log("Original Sheet name = " + varStrName);
  var arrStr = [
    ":",
    "\\\\",
    "/",
    "\\?",
    "\\*",
    "\\[",
    "]",
    "&",
    "<",
    ">",
    ",",
    "%",
    "_ _",
    "__",
    " _",
    "_ ",
  ];
  for (var i = 0; i < arrStr.length; i++) {
    varStrName = varStrName.replace(new RegExp(arrStr[i], "g"), "_");
    varStrName = varStrName.substring(0, 30);
  }
  return varStrName;
}

/*
    To check if there are any downloadable sheets available in the current active dashboard. Returns true if sheets are available for download.
*/
export function isSheetsAvailableforDownload(
  tableauViz,
  varStrDownloadSheetKeyword
) {
  var activeDashboard = tableauViz.getWorkbook().getActiveSheet();
  var sheetsOfDashboard = activeDashboard.getWorksheets();
  //To get the list of Worksheets that can be downloaded
  var dictDownloadableSheets = getSheetsInfoForDataDownload(
    sheetsOfDashboard,
    varStrDownloadSheetKeyword
  );
  //If there are no sheets to download then return false
  var intCount = Object.keys(dictDownloadableSheets).length;
  //alert(intCount + " sheets to download");
  if (typeof intCount == "undefined" || intCount <= 0) return false;
  else return true;
}

/*
    To get a list of column indices that needs additional check for row values as null or empty
	Returns a dictionary with Key - column index and Value - column name
*/
function getCheckingColumnsForSheet(dictCustomColumns) {
  var dictCheckColumns = {};

  if (
    typeof GdictRowsToIgnoreBasedOnColumnValues == "undefined" ||
    GdictRowsToIgnoreBasedOnColumnValues == null ||
    GdictRowsToIgnoreBasedOnColumnValues == ""
  )
    return dictCheckColumns;

  //To get the Dictionary for the currently active dashboard.
  var arrColsForCheckDef =
    GdictRowsToIgnoreBasedOnColumnValues[GActiveDashboardName];
  if (
    typeof arrColsForCheckDef == "undefined" ||
    arrColsForCheckDef == null ||
    arrColsForCheckDef.length <= 0
  )
    return dictCheckColumns;

  var strUcaseColumnName1 = "",
    strUcaseColumnName2 = "";
  for (var i = 0; i < arrColsForCheckDef.length; i++) {
    strUcaseColumnName1 = arrColsForCheckDef[i].trim().toUpperCase();
    for (var varColumnID in dictCustomColumns) {
      strUcaseColumnName2 = dictCustomColumns[varColumnID].trim().toUpperCase();
      if (strUcaseColumnName1 == strUcaseColumnName2)
        dictCheckColumns[varColumnID] = dictCustomColumns[varColumnID];
    }
  }
  return dictCheckColumns;
}

/*
    To check if the given string is null or empty
	Returns a boolean value
*/
function isValidValue(strValueToCheck) {
  strValueToCheck = strValueToCheck.trim().toUpperCase();
  if (strValueToCheck == "NULL" || strValueToCheck == "") return false;
  return true;
}
