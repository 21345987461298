import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import authValues from "../auth/authService";
import constValues from "../../services/constants";
import logger from "../../services/logService";
import { Auth } from "aws-amplify";
import userContext from "../auth/AuthContext";

    class ModalPopup extends Component {
        static contextType = userContext;
        popUpResTimer;
        popUpTimer;
       state = {
         counter:60,
       }

        
        handleClose = () => {
            this.props.popUp(false);
           
        }

        logout = async () => {
            this.handleClose();
        const { userEmailAddress, accessGroup, clientName } =
          await authValues.getAuthValues();
    
            logger.log(
                userEmailAddress,
                clientName,
                accessGroup,
                constValues.emptyString,
                constValues.emptyString,
                constValues.clientEventOccurred,
                constValues.descriptionValues.LoggedOut,
                constValues.currentUrl,
                constValues.clientStatusCodes.LoggedOut,
                constValues.emptyString,
                constValues.emptyString,
                constValues.emptyString,
                constValues.emptyString,
                constValues.emptyString,
                constValues.emptyString,
                constValues.emptyString
            );
    
            this.context.logout();
      };
    
        resetTimer = () => {
            this.handleClose();
            this.props.resetTimer();
            
          
        }

        async componentDidMount(){
            this.popUpTimer =  setInterval(() => {
                this.setState({ counter: this.state.counter - 1 });
            }, 1000)
            clearTimeout(this.popUpResTimer);
            this.popUpResTimer = setTimeout(() => {
                this.logout();
            }, 60000)
           
        }

       async componentWillUnmount(){
        window.clearTimeout(this.popUpResTimer);
        window.clearInterval(this.popUpTimer)
       }

        render() {
            return (
                <Fragment>
                    <div className='popup-cotainer'>
                        <Modal show={this.props.showModalPopup} onHide={this.handleClose}
                            size="sm"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered 
                        >
                            <Modal.Header>
                               Logout in {this.state.counter}s
                            </Modal.Header>
                            <Modal.Body>
                               
                                <div>
                                    <span>You seem inactive for some time.</span> 
                                    <br />
                                    <br />
                                    <span>Please click Extend to continue working.</span>
                                    <hr />
                                    <Button size='sm' style={{ margin: "5px" ,backgroundColor:"#00466c"}} type="button"  onClick={this.logout}>Logout</Button>
                                    <Button size='sm' style={{ margin: "5px",backgroundColor:"#00466c" }} type="button" onClick={this.resetTimer}>Extend</Button>
                                </div>
                            </Modal.Body>

                        </Modal >
                    </div>

                </Fragment >

            );
        }
    }
    
    export default ModalPopup;