import React, { Component } from "react";
import "../components/css/error.css";
import configvalues from "../config.json";

//Eror page
class Error extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div id="divwrap">
          <div className="errordiv">
            <h4>
              Something went wrong. Please try again.<br></br> If the problem
              persists. Please contact our{" "}
              <a href={`mailto:${configvalues.supportemailaddress}`}>
                {" "}
                Support Team
              </a>
            </h4>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Error;
