import React, { Component } from "react";
import SideNavBar from "./sidenavpage";
import axios from "axios";
import config from "../config.json";
import http from "../services/httpService";
import logger from "../services/logService";
import constValues from "../services/constants";
import authValues from "../components/auth/authService";
import ErrorPage from "../components/error";
import "../components/css/PBIReport.css";
import Loading from "../components/common/loading";
import Navbar from "../components/common/navbar";
//import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { Auth } from "aws-amplify";

class PBIDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      powerbireporttype : "",
      powerbiReportID : "",
      powerbiReportName : "",
      powerbiEmbedURL: "",
      powerbiaccessToken: "",
      PowerbiTokenStatus: "",
      loading: true,
      loadDashboard: false,
      bgColor: "#00466c",
    };
  }

  async componentDidMount() {
    const refreshtokeninmins = await this.getToken(1);
    // Calculate below timer based on values from backend
    //console.log("Mins printed here..." + refreshtokeninmins);
    if (refreshtokeninmins > 0)
      setInterval(this.getToken, (refreshtokeninmins * 60 * 1000)); // convert mins to milliseconds
  }

  // function to get the ticket from Lambda and reset the state values
  async getToken(initOption = 0) {
    const { userEmailAddress, accessGroup, clientName } =
    await authValues.getAuthValues();
    let queryString = window.location.search;
    let querystring = new URLSearchParams(queryString).get("query");
    let appid = [];
    appid.push(querystring);
    //console.log("Before calling API...");

    const sesssionDetails = await Auth.currentSession();
    let accessToken = sesssionDetails.getAccessToken().getJwtToken();
    axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

    let reqParams = {
      appid: appid,
      accessGroup: accessGroup,
      userEmailAddress: userEmailAddress,
      clientName,
    };

    //lambda call to get the Power BI token and the embed url
    const { data: token } = await http.post(
      http.apiEndPoint + "getTokenandURL",
      reqParams
    );
    
    if (token == null || token == undefined || token.status != "Success")
    { 
      console.log("Error from backend service...");
      logger.log(
        userEmailAddress,
        clientName,
        accessGroup,
        constValues.appType.PowerBI,
        constValues.emptyString,
        constValues.clientEventOccurred,
        constValues.descriptionValues.PBIDashboardPage,
        constValues.currentUrl,
        constValues.clientStatusCodes.PBIDashboardPage,
        constValues.emptyString,
        appid,
        constValues.emptyString,
        constValues.emptyString,
        constValues.emptyString,
        token.status,
        constValues.emptyString
      );
      return -1;
    }

    if (initOption == 1)
    {
      this.setState({
        powerbireporttype : token.powerbireporttype,
        powerbiReportID : token.powerbiauthreportId,
        powerbiReportName : token.powerbiauthreportname,
        powerbiEmbedURL: token.powerbiauthembedurl,
        powerbiaccessToken: token.powerbiauthembedtoken,
        PowerbiTokenStatus: token.status,
        loading: false,
        loadDashboard: true,
      });

      // PBI dashboard embed
      let embedConfig = {
        type: this.state.powerbireporttype,
        tokenType: models.TokenType.Embed,
        id: this.state.powerbiReportID,
        embedUrl: this.state.powerbiEmbedURL,
        accessToken: this.state.powerbiaccessToken,
        settings: {
            panes: {filters: { expanded: false, visible: false } },
            background: models.BackgroundType.Transparent,
        }
      };
      let reportContainer = document.getElementById("frmpbiReport");
      let report = window.powerbi.embed(reportContainer, embedConfig);
      //Event handlers of PBI dashboard
      //report.on("loaded", function (event) {console.log("Initial page load...");});
      report.on("pageChanged", function (event) {
        //console.log("Logged page change...");
        logger.log(
          userEmailAddress,
          clientName,
          accessGroup,
          constValues.appType.PowerBI,
          constValues.emptyString,
          constValues.clientEventOccurred,
          constValues.descriptionValues.DashboardSheet + event.detail.newPage.displayName + " (" + event.detail.newPage.name + ")",
          constValues.currentUrl,
          constValues.clientStatusCodes.PBIDashboardPage,
          constValues.emptyString,
          appid,
          event.detail.newPage.report.config.id,
          token.powerbiauthreportname,
          event.detail.newPage.report.config.embedUrl,
          event.detail.newPage.report.config.accessToken,
          constValues.emptyString
        );
      });
      //End of event handlers of PBI dashboard
    }
    else
    {
      let objReportContainer = document.getElementById("frmpbiReport");
      let objReport = window.powerbi.get(objReportContainer);
      await objReport.setAccessToken(token.powerbiauthembedtoken);
      //console.log("Token updated..." + new Date().toLocaleString());
    }

    logger.log(
      userEmailAddress,
      clientName,
      accessGroup,
      constValues.appType.PowerBI,
      constValues.emptyString,
      constValues.clientEventOccurred,
      constValues.descriptionValues.PBIDashboardPage,
      constValues.currentUrl,
      constValues.clientStatusCodes.PBIDashboardPage,
      constValues.emptyString,
      appid,
      token.powerbiauthreportId,
      token.powerbiauthreportname,
      token.powerbiauthembedurl,
      token.powerbiauthembedtoken,
      constValues.emptyString
    );
    
    //console.log("Value before return ... " + parseInt(token.powerbitokenexpiryinmins));
    return (parseInt(token.powerbitokenexpiryinmins));
  }

  //render dashboard
  render() {
    debugger;
    //console.log("this.state.PowerbiTokenStatus===" + this.state.PowerbiTokenStatus);
    //console.log("this.state.loadDashboard===" + this.state.loadDashboard);

    if (this.state.PowerbiTokenStatus !== "Error") {
      if (this.state.loadDashboard) {
        return (
          <React.Fragment>
              <div style={{ "padding-top": "5px" }}></div>
              <div id="frmpbiReport" className="check" style={{ "width": "100%", "height": "1200px" }}></div>
          </React.Fragment>
        );
      } else {
        return <Loading />;
      }
    } else {
      return (
        <React.Fragment>
          <ErrorPage />
          <input type="hidden" value="" id="dashboardurl"></input>
        </React.Fragment>
      );
    }
  }
}

export default PBIDashboard;
