import axios from "axios";
import authValues from "../../src/components/auth/authService";
import logger from "./logService";
import constValues from "./constants";
import { Auth } from "aws-amplify";

axios.interceptors.response.use(null, (error) => {
  if (error) {
      authValues.getAuthValues().then(({ userEmailAddress, accessGroup, clientName })=>{

    Auth.currentSession().then((sesssionDetails) => {
      let accessToken = sesssionDetails.getAccessToken().getJwtToken();
      axios.defaults.headers.common = {Authorization: `Bearer ${accessToken}`,};

      logger.log(
      userEmailAddress,
      clientName,
      accessGroup,
      constValues.emptyString,
      constValues.emptyString,
      constValues.clientEventOccurred,
      constValues.descriptionValues.UnExpectedError,
      constValues.currentUrl,
      constValues.clientStatusCodes.UnexpectedError,
      error.toString(),
      error,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString
    );
    })});
  }

  window.location.href = "/Unavailable";
  return Promise.reject(error);
});

const apiEndPoint = process.env.REACT_APP_API;

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  apiEndPoint,
};
