import React, { Component } from "react";
import axios from "axios";
import configValues from "../config.json";
import http from "../services/httpService";
import logger from "../services/logService";
import constValues from "../services/constants";
import authValues from "./auth/authService";
import Loading from "./common/loading";
import "../components/css/digitaldashboards.css";
import ErrorPage from "./error";
import { Auth } from "aws-amplify";

class Redirectapplications extends Component {
  state = {
    menuClicked: this.props.location.state,
    menuhierarchy: this.props.location.menuhierarchy,
    applications: [],
    loading: true,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location.menuhierarchy != undefined) {
      if (
        prevProps.location.menuhierarchy != this.props.location.menuhierarchy ||
        prevProps.location.state != this.props.location.state
      ) {
        //this.setState({ menuhierarchy: this.props.location.menuhierarchy });
        this.getRedirectApplications(
          this.props.location.state,
          this.props.location.menuhierarchy
        );
      }
    }
  }

  async getRedirectApplications(menuClicked, menuhierarchy) {
    const { userEmailAddress, accessGroup, clientName } =
      await authValues.getAuthValues();
    const sesssionDetails = await Auth.currentSession();
    let accessToken = sesssionDetails.getAccessToken().getJwtToken();
    axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

    //object that will be sent as post to the lambda
    //accessgroup,eamiladdress,clientName, menuclick
    const reqParams = {
      accessGroup: accessGroup,
      userEmailAddress: userEmailAddress,
      clientName,
      menuClicked,
      menuhierarchy,
    };

    //call to lambda endpoint to display the applications information
    const { data: applications } = await http.post(
      http.apiEndPoint + "redirectapplications",
      reqParams
    );

    //set the applications page with all the values and make loading value to false to stop the loading sign
    this.setState({ applications, loading: false });

    logger.log(
      userEmailAddress,
      clientName,
      accessGroup,
      constValues.emptyString,
      constValues.emptyString,
      constValues.clientEventOccurred,
      constValues.descriptionValues.RedirectApplicationsPage,
      constValues.currentUrl,
      constValues.clientStatusCodes.RedirectApplications,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString
    );
  }

  async componentDidMount() {
    this.getRedirectApplications(
      this.state.menuClicked,
      this.state.menuhierarchy
    );
  }

  //route to redirect page
  handleSubmit = (dashboardurl) => (event) => {
    window.open(dashboardurl, "_blank");
  };

  //download the help guide and show it in a seperate tab
  async downloadHelpguide(filePath) {
    const { userEmailAddress, accessGroup } = await authValues.getAuthValues();
    const sesssionDetails = await Auth.currentSession();
    let accessToken = sesssionDetails.getAccessToken().getJwtToken();
    axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

    //complete file path for constructing the presigned url
    let reqParams = {
      filePath: filePath,
      accessGroup: accessGroup,
      userEmailAddress: userEmailAddress,
    };
    const res = await http.post(
      http.apiEndPoint + "downloadgenericfiles",
      reqParams
    );

    if (res.data == configValues.errorResponse) {
      alert(configValues.fileDownloadError);
    } else {
      window.open(res.data);
    }
  }

  //render the help document icon
  renderHelpDocumentDisplay(filePath) {
    if (filePath) {
      return (
        <i
          className="fa fa-info float-right p-1 my-1"
          title="Help Document"
          data-placement="top"
          data-toggle="tooltip"
          onClick={async () => {
            await this.downloadHelpguide(filePath);
          }}
        ></i>
      );
    } else {
      //to maintain the length of the div boxhide the icon
      return (
        <i
          className="fa fa-download float-right p-1 my-1 fa-hide"
          title=""
          data-placement="top"
          data-toggle="tooltip"
        ></i>
      );
    }
  }

  //Display errorPage or dashboards
  displayApplications() {
    if (this.state.applications.status == configValues.errorResponse) {
      return (
        <React.Fragment>
          <br />
          <ErrorPage />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <br />
          <div className="container">
            <h4>{this.props.location.state}</h4>
            <div className="row">
              {this.state.applications.map((application) => (
                <div className="col-md-4" key={application.title}>
                  <div className="card mb-4 box-shadow">
                    {" "}
                    <img
                      onClick={this.handleSubmit(application.url)}
                      className="card-img-top"
                      src={application.image}
                      alt="Card image cap"
                      id="cardhover"
                    />
                    <div className="card-body">
                      <span className="card-text">{application.title}</span>
                      {this.renderHelpDocumentDisplay(
                        application.helpdoclocation
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  render() {
    if (this.state.loading) return <Loading />;
    return <React.Fragment>{this.displayApplications()}</React.Fragment>;
  }
}

export default Redirectapplications;
