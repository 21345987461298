import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import axios from "axios";
import SideNavBar from "./sidenavpage";
import http from "../services/httpService";
import logger from "../services/logService";
import constValues from "../services/constants";
import authValues from "../components/auth/authService";
import ErrorPage from "../components/error";
import Loading from "./common/loading";
import "../components/css/ssms.css";
import Ssmsimage from "../images/SSMS.jpg";
import Navbar from "../components/common/navbar";
import { Auth } from "aws-amplify";

class SSMS extends Component {
  state = {
    menuClicked: this.props.location.state,
    menuhierarchy: this.props.location.menuhierarchy,
    allSSMSList: [],
    loading: true,
  };

  async getSSMS(menuClicked, menuhierarchy) {
    const { userEmailAddress, accessGroup, clientName } =
      await authValues.getAuthValues();
    const sesssionDetails = await Auth.currentSession();
    let accessToken = sesssionDetails.getAccessToken().getJwtToken();
    axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

    let reqParams = {
      menuClicked,
      accessGroup,
      userEmailAddress,
      clientName,
      menuClicked,
      menuhierarchy,
    };
    const { data: data } = await http.post(
      http.apiEndPoint + "getSSMS",
      reqParams
    );
    this.setState({ allSSMSList: data, loading: false });

    logger.log(
      userEmailAddress,
      clientName,
      accessGroup,
      constValues.appType.SSMS,
      constValues.emptyString,
      constValues.clientEventOccurred,
      constValues.descriptionValues.SSMSPage,
      constValues.currentUrl,
      constValues.clientStatusCodes.SSMSPage,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString
    );
  }
  async componentDidMount() {
    this.getSSMS(this.state.menuClicked, this.state.menuhierarchy);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.menuhierarchy != undefined) {
      if (
        prevProps.location.menuhierarchy != this.props.location.menuhierarchy ||
        prevProps.location.state != this.props.location.state
      ) {
        this.getSSMS(
          this.props.location.state,
          this.props.location.menuhierarchy
        );
      }
    }
  }

  async handleSubmit(filePath) {
    const { userEmailAddress, accessGroup, clientName } =
      await authValues.getAuthValues();
    const sesssionDetails = await Auth.currentSession();
    let accessToken = sesssionDetails.getAccessToken().getJwtToken();
    axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

    let reqParams = {
      filePath: filePath,
      accessGroup: accessGroup,
      userEmailAddress: userEmailAddress,
      clientName,
    };
    const res = await http.post(http.apiEndPoint + "downloadssms", reqParams);

    if (res.data == "Error") {
      alert(
        "Something went wrong! Please try again. If problem continues please contact our support Team"
      );
    } else {
      window.open(res.data);
    }
  }

  displaySSMS() {
    if (this.state.allSSMSList.status == "Error") {
      return (
        <React.Fragment>
          <ErrorPage />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="container">
            <div className="row featurette">
              <div className="col-md-5">
                <img
                  className="featurette-image img-responsive center-block"
                  src={Ssmsimage}
                  height="100%"
                  width="100%"
                  alt="Generic placeholder"
                />
              </div>
              <div className="col-md-7">
                <h4 className="featurette-heading">
                  Best in Class Analytical Data Warehouse.{" "}
                </h4>
                <p className="lead">
                  Argus Advisory analytical data warehouse uses analytics-friendly
                  schemas that make analytics, modeling, and reporting easier
                  than on most common operational systems. Argus Advisory's
                  professionally managed data warehouses are built on over a
                  decade of warehousing industry data for the largest banks in
                  the US. Additionally, Argus Advisory's validation process assures you
                  that you can rely on the data loaded to be accurate and
                  complete.
                </p>
                <br></br>

                {this.state.allSSMSList.map((ssmsObj) => (
                  <Button
                    className="ssmsbutton"
                    onClick={async () => {
                      await this.handleSubmit(ssmsObj.s3foldername);
                    }}
                  >
                    {ssmsObj.description}
                  </Button>
                ))}
              </div>
              <br />
              <br />
            </div>
          </div>
          {/* </div> */}
        </React.Fragment>
      );
    }
  }

  render() {
    if (this.state.loading) return <Loading />;
    return this.displaySSMS();
  }
}

export default SSMS;
