import React, { Component } from "react";
import { push as Menu } from "react-burger-menu";
import Avatar from "react-avatar";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import config from "../config.json";
import http from "../services/httpService";
import authValues from "../components/auth/authService";
import Logout from "../components/Logout";
import Tree from "../components/Tree";
import closeimage from "../images/close.png";
import "../components/css/sidenav.css";
import moment from "moment";
import { Auth } from "aws-amplify";


  class SideNavBar extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        authenticated: null,
        user: null,
        userName: "",
        userInfo: "",
        idToken: "",
        accessToken: "",
        sideNavItems: [],
        validateUseraccess: true,
        isLoaded: true,
        clientLogo: "#",
      };
      this.checkAuthentication = this.checkAuthentication.bind(this);
      this.checkAuthentication();
    }

    async checkAuthentication() {
      let authenticated = null;
      try {
        await Auth.currentAuthenticatedUser();
        authenticated = true;
      } catch (err) {
        authenticated = false;
      }
      if (authenticated !== this.state.authenticated) {
        this.setState({ authenticated });
      }
    }

    async getSideNav() {
      const { userEmailAddress, accessGroup, clientName } =
        await authValues.getAuthValues();

      const reqParams = {
        accessGroup: accessGroup,
        userEmailAddress: userEmailAddress,
        clientName: clientName,
      };

      const sesssionDetails = await Auth.currentSession();
      let accessToken = sesssionDetails.getAccessToken().getJwtToken();
      axios.defaults.headers.common = {
        Authorization: `Bearer ${accessToken}`,
      };

      const { data: sideNavItems } = await http.post(
        http.apiEndPoint + "getSideNav",
        reqParams
      );
      this.setState({ sideNavItems });
    }

    async getClientLogo() {
      const { userEmailAddress, accessGroup, clientName } =
        await authValues.getAuthValues();

      const reqParams = {
        accessGroup: accessGroup,
        userEmailAddress: userEmailAddress,
        clientName: clientName,
      };

      const sesssionDetails = await Auth.currentSession();
      let accessToken = sesssionDetails.getAccessToken().getJwtToken();
      axios.defaults.headers.common = {
        Authorization: `Bearer ${accessToken}`,
      };
      const { data: logo } = await http.post(
        http.apiEndPoint + "getClientLogo",
        reqParams
      );

      // console.log(logo);

      this.setState({ clientLogo: logo[0].clientlogo });
    }

    async validateUser() {
      const { userEmailAddress, accessGroup, clientName } =
        await authValues.getAuthValues();

      const reqParams = {
        accessGroup: accessGroup,
        userEmailAddress: userEmailAddress,
        clientName: clientName,
      };

      const sesssionDetails = await Auth.currentSession();
      let accessToken = sesssionDetails.getAccessToken().getJwtToken();
      axios.defaults.headers.common = {
        Authorization: `Bearer ${accessToken}`,
      };
      const { data: validateUseraccess } = await http.post(
        http.apiEndPoint + "validateuseraccess",
        reqParams
      );
      this.setState({ validateUseraccess, isLoaded: true });
    }

    async componentDidMount() {
      Auth.currentSession().then((res) => {
        const accessToken = res.accessToken;
        if (accessToken === undefined) {
          this.componentDidMount();
        } else {
          this.setState({ accessToken });
          this.getSideNav();
          this.getClientLogo();
          this.validateUser();
        }
      });
    }

    componentDidUpdate() {
      this.checkAuthentication();
    }
    handleStateChange(state) {
      this.setState({ menuOpen: state.isOpen });
    }
    displaySideNavigation() {
      if (!this.state.validateUseraccess) {
        return <Logout page="accessIssue" />;
      } else {
        return (
          <React.Fragment>
            {this.state.isLoaded && (
              <Menu
                customCrossIcon={<img src={closeimage} />}
                width={"18%"}
                {...this.props}
                noOverlay
                isOpen={this.props.isOpen}
                onStateChange={(state) => this.handleStateChange(state)}
              >
                {this.state.clientLogo != "#" && (
                  <img
                    src={this.state.clientLogo}
                    alt="imagelogo"
                    className="bg-img img-thumbnail"
                  />
                )}

                <Link
                  id="home"
                  className="menu-item active"
                  to="Landing"
                  key="key"
                >
                  Home
                </Link>
                {/*<Link
                  id="home"
                  className="menu-item active"
                  to="Thoughtspot"
                  key="key"
                >
                  Thoughtspot
                </Link>*/}

                {this.state.sideNavItems.map((sideNavItem) => {
                  // console.log(sideNavItem);
                  if (sideNavItem.children.length === 0) {
                    return (
                      <Link
                        id={sideNavItem.menu}
                        className="menu-item active"
                        title={sideNavItem.menu}
                        to={{
                          pathname: sideNavItem.navigateto,
                          state: sideNavItem.menu,
                          menuhierarchy: sideNavItem.menuhierarchy,
                        }}
                        // onClick={this.changeNav()}
                        key={sideNavItem.menu}
                      >
                        {sideNavItem.menu}
                      </Link>
                    );
                  } else {
                    return (
                      <Link className="treeView">
                        <Tree data={[sideNavItem]} />
                      </Link>
                    );
                  }
                })}
              </Menu>
            )}
          </React.Fragment>
        );
      }
    }

    render() {
      if (
        window.location.pathname == "/LogoutRedirect" ||
        window.location.pathname == "/AccessDenied"
      ) {
        return <React.Fragment></React.Fragment>;
      }
      return <React.Fragment>{this.displaySideNavigation()}</React.Fragment>;
    }
  }
  
  export default SideNavBar;