import React, { Component } from "react";
import "../components/css/error.css";
import { withRouter } from 'react-router-dom';
import http from "../services/httpService";

  class logout extends Component {
    //based on the props from the component logout, redirect and show the appropriate error message
    // User will be logged out based on OKTA App settings.
    logout = async () => {
      if (this.props.page == "accessIssue") {
        this.props.history.push("/AccessDenied");
      } else {
        this.props.history.push("/Error");
      }
    };
  
    componentDidMount() {
      this.logout();
    }
  
    render() {
      return (
        <React.Fragment>
        </React.Fragment>
      );
    }
  }
  
  export default withRouter(logout);
  