import React, { Component } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import SideNavBar from "./sidenavpage";
import config from "../config.json";
import http from "../services/httpService";
import logger from "../services/logService";
import constValues from "../services/constants";
import authValues from "../components/auth/authService";
import "../components/css/analyzer.css";
import Navbar from "../components/common/navbar";
import { Auth } from "aws-amplify";

//Render analyzer component
class Analyzer extends Component {
  async openAnalyzer() {
    const { userEmailAddress, accessGroup } = await authValues.getAuthValues();
    const sesssionDetails = await Auth.currentSession();
    let accessToken = sesssionDetails.getAccessToken().getJwtToken();
    axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

    //complete file path for constructing the presigned url
    let filePath = config.analyzerGuidePath;
    let reqParams = {
      filePath: filePath,
      accessGroup: accessGroup,
      userEmailAddress: userEmailAddress,
    };
    const res = await http.post(
      http.apiEndPoint + "downloadgenericfiles",
      reqParams
    );

    if (res.data == config.errorResponse) {
      alert(config.fileDownloadError);
    } else {
      window.open(res.data);
    }
  }

  relaunch() {
    window.location.reload();
  }

  async componentDidMount() {
    const { userEmailAddress, accessGroup, clientName } =
      await authValues.getAuthValues();
    const sesssionDetails = await Auth.currentSession();
    let accessToken = sesssionDetails.getAccessToken().getJwtToken();
    axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

    logger.log(
      userEmailAddress,
      clientName,
      accessGroup,
      constValues.emptyString,
      constValues.emptyString,
      constValues.clientEventOccurred,
      constValues.descriptionValues.AnalyzerPage,
      constValues.currentUrl,
      constValues.clientStatusCodes.AnalyzerPage,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString
    );
  }

  render() {
    return (
      <React.Fragment>
        <React.Fragment>
          <div>
            {" "}
            <Button
              className="analyzerbtn"
              onClick={async () => {
                await this.openAnalyzer();
              }}
            >
              <i className="fa fa-download"></i> Analyzer Help guide
            </Button>
            <Button className="analyzerbtn" onClick={this.relaunch}>
              Relaunch Analyzer
            </Button>
          </div>

          <iframe src={config.analyzerLink} id="analyzerDivId"></iframe>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default Analyzer;
