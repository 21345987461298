import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import axios from "axios";
import config from "../config.json";
import http from "../services/httpService";
import authValues from "../components/auth/authService";
import "../components/css/whatsnewaccordian.css";
import { Auth } from "aws-amplify";

class WhatsnewAccordion extends Component {
  state = {
    whatsNewMessages: [],
    isLoaded: false,
  };

  async componentDidMount() {
    const { userEmailAddress, accessGroup, clientName } =
      await authValues.getAuthValues();
    const reqParams = {
      userEmailAddress,
      accessGroup,
      clientName,
    };

    const sesssionDetails = await Auth.currentSession();
    let accessToken = sesssionDetails.getAccessToken().getJwtToken();
    axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

    const { data: whatsNewMessages } = await http.post(
      http.apiEndPoint + "whatsnewnotification",
      reqParams
    );
    this.setState({ whatsNewMessages });
    this.setState({ isLoaded: true }, () => this.props.onChildLoad());
    //console.log(whatsNewMessages);
  }

  //function to add whatsnew header if we need to render whatsnew messages
  //if there are any messages that need to be shown
  //"WhatsNew?" header will be shown before the component
  displayHeaderForWhatsNew() {
    let renderWhatsNewHeader = [];
    let whatsNewMessages = this.state.whatsNewMessages;
    if (whatsNewMessages === undefined) return;

    if (whatsNewMessages.length > 0) {
      let htmlHeader = <h5> &nbsp;&nbsp;Whats New?</h5>;
      renderWhatsNewHeader.push(htmlHeader);
      return <React.Fragment>{renderWhatsNewHeader}</React.Fragment>;
    }
  }

  //function to display whatsnew Messages
  //if no messages return nothing
  displayWhatsNewMessages() {
    let whatsNewMessages = this.state.whatsNewMessages;
    let whatsnewDivClass = ["whatsnewaccordian"];
    if (whatsNewMessages === undefined) return;

    //hide the whatsnew div if no messages to display
    if (whatsNewMessages.length == 0) {
      whatsnewDivClass.push("hide");
    }

    return (
      <React.Fragment>
        <div className={whatsnewDivClass.join(" ")}>
          <Accordion
            allowMultipleExpanded={true}
            allowZeroExpanded={true}
            preExpanded={["yes"]}
          >
            {whatsNewMessages.map((whatsNewMessage, i) => (
              <AccordionItem
                expanded={true}
                key={whatsNewMessage.notificationid}
                uuid={whatsNewMessage.firstMessage}
              >
                <AccordionItemHeading>
                  <AccordionItemButton>
                    {whatsNewMessage.notificationheader}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: whatsNewMessage.notificationmessage,
                    }}
                  ></div>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </React.Fragment>
    );
  }

  render() {
    //console.log("props" + this.props);
    //alert(this.props.value);

    return (
      <React.Fragment>
        {this.state.isLoaded && !this.props.loading && (
          <React.Fragment>
            <span style={{ paddingtop: "100px" }}>
              {this.displayHeaderForWhatsNew()}
            </span>
            {this.displayWhatsNewMessages()}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default WhatsnewAccordion;
