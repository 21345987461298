const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_COGNITOREGION,
    userPoolId: process.env.REACT_APP_COGNITOPOOLID,
    userPoolWebClientId: process.env.REACT_APP_API_COGNITOCLIENTID,
  },
  oauth: {
    domain: process.env.REACT_APP_COGNITODOMAIN,
    scope: [
      "phone",
      "email",
      "openid",
      "aws.cognito.signin.user.admin",
      "profile",
    ],
    redirectSignIn: process.env.REACT_APP_LOGINURL,
    redirectSignOut: process.env.REACT_APP_LOGOUTURL,
    responseType: "code",
  },
};

export default awsConfig;
