import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import SideNavBar from "./sidenavpage";
import config from "../config.json";
import http from "../services/httpService";
import authValues from "../components/auth/authService";
import "../components/css/reports.css";
import axios from "axios";
import logger from "../services/logService";
import constValues from "../services/constants";
import ErrorPage from "../components/error";
import Loading from "../components/common/loading";
import { Auth } from "aws-amplify";

class Reports extends Component {
  state = {
    menuClicked: this.props.location.state,
    menuhierarchy: this.props.location.menuhierarchy,
    allReportsList: [],
    loading: true,
  };

  //download fiile from s3
  async downloadReport(tableMeta) {
    const { userEmailAddress, accessGroup, clientName } =
      await authValues.getAuthValues();
    const sesssionDetails = await Auth.currentSession();
    let accessToken = sesssionDetails.getAccessToken().getJwtToken();
    axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

    //complete file path for constructing the presigned url
    let filePath = tableMeta.rowData[2];
    let reqParams = {
      filePath: filePath,
      accessGroup: accessGroup,
      userEmailAddress: userEmailAddress,
      clientName,
    };
    const res = await http.post(http.apiEndPoint + "downloadreport", reqParams);

    if (res.data == "Error") {
      alert(
        "Something went wrong! Please try again. If problem continues please contact our support Team"
      );
    } else {
      window.open(res.data);
    }
  }

  async getReports(menuClicked, menuhierarchy) {
    const { userEmailAddress, accessGroup, clientName } =
      await authValues.getAuthValues();
    const sesssionDetails = await Auth.currentSession();
    let accessToken = sesssionDetails.getAccessToken().getJwtToken();
    axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

    let reqParams = {
      menuClicked,
      accessGroup,
      userEmailAddress,
      clientName,
      menuhierarchy,
    };
    const { data: data } = await http.post(
      http.apiEndPoint + "getReports",
      reqParams
    );
    this.setState({ allReportsList: data, loading: false });

    logger.log(
      userEmailAddress,
      clientName,
      accessGroup,
      constValues.appType.Reports,
      constValues.emptyString,
      constValues.clientEventOccurred,
      constValues.descriptionValues.ReportsPage,
      constValues.currentUrl,
      constValues.clientStatusCodes.ReportsPage,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString
    );
  }
  async componentDidMount() {
    this.getReports(this.state.menuClicked, this.state.menuhierarchy);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.menuhierarchy != undefined) {
      if (
        prevProps.location.menuhierarchy != this.props.location.menuhierarchy ||
        prevProps.location.state != this.props.location.state
      ) {
        //console.log("new component load");
        this.setState({ menuhierarchy: this.props.location.menuhierarchy });
        this.getReports(
          this.props.location.state,
          this.props.location.menuhierarchy
        );
      }
    }
  }

  displayReports() {
    //column descriptions for Reports
    //third column is a hidden column which serves as  complete file location name for downloading file
    const columns = [
      {
        name: "Report Name",
        options: {
          customBodyRender: (reportName, tableMeta, filePath) => {
            return (
              <a
                id="filenamehover"
                to="/#"
                onClick={async () => {
                  await this.downloadReport(tableMeta);
                }}
                download
              >
                {tableMeta.rowData[0]}
              </a>
            );
          },
        },
      },
      { name: "Last Modified", options: { sortDirection: "desc" } },
      {
        name: "",
        options: {
          display: false,
        },
      },
    ];

    const options = {
      filterType: "dropdown",
      filter: false,
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: false,
      searchOpen: true,
      searchPlaceholder: "Search your Reports",
      textLabels: {
        body: {
          noMatch: "No reports available for display",
        },
      },
    };
    if (this.state.allReportsList.status == config.errorResponse) {
      return (
        <React.Fragment>
          <ErrorPage />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div id="muidatatable">
          <MUIDataTable
            title={""}
            data={this.state.allReportsList}
            columns={columns}
            options={options}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    //render loading icon until we get a response from server
    if (this.state.loading) return <Loading />;
    return this.displayReports();
  }
}

export default Reports;
