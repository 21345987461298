import React, { Component } from "react";
import "./App.css";
import Navbar from "./components/common/navbar";
import DigitalDashboards from "./components/DigitalDashoards";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DigitalDashboardDisplay from "./components/DashBoardDisplay";
import Landing from "./components/Landing";
import Reports from "./components/Reports";
import LogoutRedirect from "./components/LogoutRedirect";
import Analyzer from "./components/Analyzer";
import Contact from "./components/Contactus";
import AccessDenied from "./components/AccessDenied";
import Loading from "./components/common/loading";
import Logout from "./components/Logout";
import ErrorPage from "./components/error.jsx";
import AuthCheck from "./Authcheck";
import SSMS from "./components/SSMS";
import Footer from "./components/common/footer";
import Redirectapplications from "./components/Redirectapplications"
import SideNav from "./components/sidenavpage"
//import ThoughtSpot from "./components/thoughtspot"
import CookieConsent from "react-cookie-consent";
import PBIDashboardDisplay from "./components/PBIDashboard";
import ModalPopup from "./components/common/modal_popup";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import { AuthContext } from "./components/auth/AuthContext";

class App extends Component {
  constructor(props){
    super(props);
    if (window.trustedTypes && window.trustedTypes.createPolicy) {
      window.trustedTypes.createPolicy('default', {
         createHTML: (string) => {
           if(string.toString().includes("<script") || string.toString().includes("</script>")) {
             return;
           }else if(string.toString().includes("https://") && string.toString().includes("href=")){
             let containInvalidURL = false;
             string.toString().split("href=").map((el,index)=> {
               if(el.includes("https://")) {
                const urlInString = new URL(el.substring(1,el.indexOf("\">")));
                if(!(urlInString.hostname === window.location.hostname)) {
                       containInvalidURL = true;
                }
               }
             });
                return containInvalidURL? "" : string;
           }
           return string;
         },
         createScriptURL: string => string, 
         createScript: string => string,

       });
     }
  }
  state = {
    showModalPopup: false,
  }
  isShowPopup = (status) => {  
    this.setState({ showModalPopup: status });  
  };
  
  shouldResetTimer = false;
  resetTimer = ()=>{
    this.showModalPopup =true;
  }
  render() {
   
    return (
      <React.Fragment>
        <AuthContext>
        <Router>
          {/* resetTimerNav = {this.shouldResetTimer} */}
          {/* {popUp = {this.isShowPopup}} */}
          <Navbar popUp = {this.isShowPopup} />
           {this.state.showModalPopup &&  <ModalPopup
              showModalPopup={this.state.showModalPopup}
              onPopupClose={this.state.showModalPopup}
              resetTimer = {this.resetTimer}
              popUp = {this.isShowPopup}
              popUpStat = {this.state.showModalPopup}
            ></ModalPopup>}

          <SideNav
            pageWrapId={"divwrap"}
            id = {"sideNav"}
            isOpen={true}
            htmlClassName
            bodyClassName
          />
          <main id="divwrap">
            <ProtectedRoute exact path="/" component={Landing} />
            <ProtectedRoute path="/DigitalDashboards" component={DigitalDashboards} />
            <ProtectedRoute
              path="/DashboardDisplay"
              component={DigitalDashboardDisplay}
            />

            <ProtectedRoute path="/Landing" exact={true} component={Landing} />

            <ProtectedRoute path="/Reports" component={Reports} />
            <ProtectedRoute path="/Analyzer" component={Analyzer} />
            <ProtectedRoute path="/SSMS" component={SSMS} />
            <ProtectedRoute path="/Applications" component={Redirectapplications} />
            <ProtectedRoute path="/PBIDashboard" component={PBIDashboardDisplay} />

            <Route path="/LogoutRedirect" component={LogoutRedirect} />
            <Route path="/Contact" component={Contact} />
            <Route path="/AccessDenied" component={AccessDenied} />
            <Route path="/Loading" component={Loading} />
            <Route path="/Unavailable" component={Logout} />
            <Route path="/Error" component={ErrorPage} />
            <Route path="/AuthCheck" component={AuthCheck} />     
            {/*<Route path="/Thoughtspot" component={ThoughtSpot} />*/}
          </main>
        </Router>
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#2B373B", fontSize: "13px" }}
          expires={30}
        >
          This website uses cookies to enhance the user experience when you
          visit. By using this website, you consent to our use of these cookie.
          Find out more about how we use our cookies and how to manage them by
          reading our{" "}
          <a
            href="https://www.argusinformation.com/cookie-policy"
            target="_blank"
          >
            cookie policy
          </a>{" "}
        </CookieConsent>
        </AuthContext>
      </React.Fragment>
    );
  }
}

export default App;
