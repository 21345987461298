import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../components/css/tree.css";

const Tree = ({ data = [] }) => {
  return (
    <div className="d-tree">
      <ul className="d-flex d-tree-container flex-column">
        {data.map((tree) => (
          <TreeNode node={tree} />
        ))}
      </ul>
    </div>
  );
};

const TreeNode = ({ node }) => {
  const [childVisible, setChildVisiblity] = useState(false);

  //render children or not
  const hasChild = node.children ? true : false;

  return (
    <li className="d-tree-node border-0">
      <div className="d-flex" onClick={(e) => setChildVisiblity((v) => !v)}>
        {hasChild && (
          <div
            className={`d-inline d-tree-toggler ${
              childVisible ? "active" : ""
            }`}
            onClick={(event) => event.preventDefault()}
          >
            {" "}
            <i
              className="fa fa-caret-right"
              aria-hidden="true"
              onClick={(event) => event.preventDefault()}
            ></i>
          </div>
        )}

        <div
          className="col d-tree-head"
          onClick={(event) => event.preventDefault()}
        >
          {node.navigateto === undefined || node.navigateto === "#" ? (
            <Link
              className="linkitem"
              onClick={(event) => event.preventDefault()}
              key={node.menu}
            >
              {node.menu}
            </Link>
          ) : (
            <Link
              className="linkitem"
              to={{
                pathname: `${node.navigateto}`,
                state: node.menu,
                menuhierarchy: node.menuhierarchy,
              }}
              key={node.menu}
            >
              {node.menu}
            </Link>
          )}
        </div>
      </div>

      {hasChild && childVisible && (
        <div
          className="d-tree-content"
          onClick={(event) => event.preventDefault()}
        >
          <ul className="d-flex d-tree-container flex-column">
            <Tree data={node.children} />
          </ul>
        </div>
      )}
    </li>
  );
};

export default Tree;
