import React, { Component } from "react";
import configvalues from "../config.json";
import authValues from "./auth/authService";
import "../components/css/contact.css";
import axios from "axios";
import http from "../services/httpService";
import { Auth } from "aws-amplify";
import userContext from "./auth/AuthContext";

class Contact extends Component {
  static contextType = userContext;
  state = {
    contactInfo: [],
    loading: false,
  };
  onChildLoad = () => {};

  async componentDidMount() {
    const sesssionDetails = await Auth.currentSession();
    let accessToken = sesssionDetails.getAccessToken().getJwtToken();
    axios.defaults.headers.common = {
      Authorization: `Bearer ${accessToken}`,
    };

    const { userEmailAddress, accessGroup, clientName } =
      await authValues.getAuthValues();
    let reqParams = {
      accessGroup: accessGroup,
      userEmailAddress: userEmailAddress,
      clientName: clientName,
    };

    const { data: contactInfo } = await http.post(
      http.apiEndPoint + "getContactInfo",
      reqParams
    );

    this.setState({ contactInfo, loading: true });
  }

  render() {
    let loginStatusCheck = this.context.isAuth;

    if (this.state.contactInfo.length > 0) {
      var {
        supportemailaddress,
        supporthours,
        supportphonenumber,
      } = this.state.contactInfo[0];
    }

    if (loginStatusCheck) {
      return (
        <React.Fragment>
          {this.state.loading && (
            <React.Fragment>
              {" "}
              <div id="divwrap">
                <div className="contactusdiv">
                  <h2> Contact us for any questions/feedback regarding the portal or its contents. </h2>
                  <br />
                  {supportemailaddress != "#" && (
                    <h2>
                      Email :{" "}
                      <a href={`mailto:${supportemailaddress}`}>
                        {supportemailaddress}
                      </a>
                    </h2>
                  )}
                  <br></br>
                  {supportphonenumber != "#" && (
                    <h2>{`Phone : ${supportphonenumber}`}</h2>
                  )}
                  <br></br>
                  {supporthours != "#" && (
                    <h2>{`Support hours : ${supporthours}`}</h2>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div id="divwrap">
            <div className="contactusdiv">
              <h2>
                {" "}
                Email :{" "}
                <a href={`mailto:${configvalues.supportemailaddress}`}>
                  argustoolkit@argusinformation.com
                </a>
              </h2>
              <br></br>
              <h2>{`Phone : ${configvalues.supportnumber}`}</h2>
              <br></br>
              <h2> {`Support hours : ${configvalues.supporthours}`}</h2>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default Contact;
