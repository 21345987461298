import config from "../config.json";
import http from "./httpService";
import axios from "axios";
import { Auth } from "aws-amplify";

async function log(
  useremailaddress,
  clientname,
  accessgroup,
  appType,
  ipaddress,
  eventOccurred,
  description,
  accessedURL,
  statusCode,
  errorMessage,
  columnValue1,
  columnValue2,
  columnValue3,
  columnValue4,
  columnValue5,
  columnValue6
) {
  const logdata = {
    useremailaddress,
    clientname,
    accessgroup,
    appType,
    ipaddress,
    eventOccurred,
    description,
    accessedURL,
    statusCode,
    errorMessage,
    columnValue1,
    columnValue2,
    columnValue3,
    columnValue4,
    columnValue5,
    columnValue6,
  };

  const sesssionDetails = await Auth.currentSession();
  let accessToken = sesssionDetails.getAccessToken().getJwtToken();
  axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  const { data } = await http.post(http.apiEndPoint + "logdata", logdata);
  //console.log(data);
}

export default {
  log,
};
