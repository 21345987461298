import { Route }  from "react-router-dom";
import React, { Component } from "react";
import userContext from "./AuthContext";
import { withRouter } from 'react-router-dom';
import { matchPath } from 'react-router-dom';


  class ProtectedRoute extends Component{
  static contextType = userContext;
  
  componentDidMount() {
    const match = matchPath(this.props.location.pathname,{...this.props});
    if(!this.context.isAuth && match){
      this.context.authenticate();
    }
  }

  componentDidUpdate() {
    const match = matchPath(this.props.location.pathname,{...this.props});
    if(!this.context.isAuth && match){
      this.context.authenticate();
    }
  }

  render() {
    return(this.context.isAuth?
     <Route {...this.props} />:<React.Fragment></React.Fragment>);
  }
}


export default withRouter(ProtectedRoute);