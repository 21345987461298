import React, { Component } from "react";
import "../css/footer.css";
import configValues from "../../config.json";

const FooterPage = () => {
  return (
    <div className="footer">
      &copy; {new Date().getFullYear()} Powered by:{" "}
      <a href={configValues.argusPageUrl} target="blank">
        Argus Advisory A TransUnion<span class="sup">®</span> Company
      </a>
    </div>
  );
};

export default FooterPage;
