import React,{createContext,Component} from "react";
import { Amplify, Auth, Hub } from "aws-amplify";
import awsConfig from "../../aws-exports";


Amplify.configure(awsConfig);
export const userContext = createContext(null);

export class AuthContext extends Component{
  state = {
    isAuth: false
  };


  authenticate = async ()=>{
    if(!this.state.isAuth){
    try {
      await Auth.currentAuthenticatedUser({bypassCache:true});
      this.setState({isAuth:true});
    } catch (err) {
      this.login();
    }
  }
  }

  logout = ()=>{
    Auth.signOut({ global: true });
  }

  login = ()=>{
    Auth.federatedSignIn({ customProvider: process.env.REACT_APP_FEDERATED_IDP});
  }

  render(){
    const {isAuth} = this.state;
    const {authenticate,login,logout} = this;
    const auth = {
      isAuth:isAuth,
      authenticate:authenticate,
      login:login,
      logout:logout
    }
    return <userContext.Provider value={auth}>{this.props.children}</userContext.Provider>
  }
}

export default userContext;
