import jwt_decode from "jwt-decode";
import {Auth} from "aws-amplify";

//function will retrieve email, usergroups and clientname from idToken
async function getAuthValues() {
  let userEmail = undefined;
  let group = undefined;
  let client = undefined;
  let user = undefined;
  try{
  const sesssionDetails = await Auth.currentSession();
  const idToken = jwt_decode(sesssionDetails.getIdToken().getJwtToken());
  userEmail = idToken.email?idToken.email:"";
  group = idToken["custom:gemini_user_role"]?idToken["custom:gemini_user_role"]
    .slice(1, -1)
    .split(",")
    .map((item) => item.trim()):[];
    client = idToken["custom:clientName"]
    ? idToken["custom:clientName"]
    : "";
    user = idToken.email?idToken.email:"";
  }
  catch(err){
    userEmail = undefined;
    group = undefined;
    client = undefined;
    user = undefined;
  }

  const userEmailAddress = userEmail;
  const accessGroup = group;
  const clientName = client;
  const userName = user;

  return { userEmailAddress, accessGroup, clientName, userName };
}


export default {
  getAuthValues,
};
