import React, { Component } from "react";
import "../css/loading.css";

//renders the loading/spinning component on the screen until all the components are loaded
class Loading extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="busy-indicator">
          <div>
          <i className="fa fa-refresh fa-spin fa-3x fa-fw loadingscreen"></i>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Loading;
