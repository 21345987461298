import React, { Component } from "react";
import Loading from "./common/loading";
import "../components/css/LogoutRedirect.css";
import userContext from "./auth/AuthContext";

class LogoutRedirect extends Component {
  static contextType = userContext;
  componentDidMount() {
    this.context.logout();
  }
  render() {
    return (
      <React.Fragment>
        <Loading />
      </React.Fragment>
    );
  }
}

export default LogoutRedirect;
