import React, { Component } from "react";
import SideNavBar from "./sidenavpage";
import axios from "axios";
import configValues from "../config.json";
import http from "../services/httpService";
import logger from "../services/logService";
import constValues from "../services/constants";
import authValues from "../components/auth/authService";
import Loading from "../components/common/loading";
import "../components/css/digitaldashboards.css";
import ErrorPage from "../components/error";
import DashBoardDisplay from "../components/DashBoardDisplay";
import { Auth } from "aws-amplify";

class DigitalDashboards extends Component {
  state = {
    menuClicked: this.props.location.state,
    menuhierarchy: this.props.location.menuhierarchy,
    headerName: "",
    dashboards: [],
    loading: true,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location.menuhierarchy != undefined) {
      if (
        prevProps.location.menuhierarchy != this.props.location.menuhierarchy ||
        prevProps.location.state != this.props.location.state
      ) {
        //console.log("new component load");
        // this.setState({ menuhierarchy: this.props.location.menuhierarchy });
        this.getDashboards(
          this.props.location.state,
          this.props.location.menuhierarchy
        );
      }
    }
  }

  async getDashboards(menuClicked, menuhierarchy) {
    const { userEmailAddress, accessGroup, clientName } =
    await  authValues.getAuthValues();
    const sesssionDetails = await Auth.currentSession();
    let accessToken = sesssionDetails.getAccessToken().getJwtToken();
    axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

    //object that will be sent as post to the lambda
    //accessgroup,eamiladdress,clientName, menuclick
    const reqParams = {
      accessGroup,
      userEmailAddress,
      clientName,
      menuClicked,
      menuhierarchy,
    };

    //call to lambda endpoint to display the dashboard information
    const { data: dashboards } = await http.post(
      http.apiEndPoint + "dashboards",
      reqParams
    );

    //set the dashboard page with all the values and make loading value to false to stop the loading sign
    this.setState({ dashboards: dashboards, loading: false });

    logger.log(
      userEmailAddress,
      clientName,
      accessGroup,
      constValues.emptyString,
      constValues.emptyString,
      constValues.clientEventOccurred,
      constValues.descriptionValues.DashboardPage,
      constValues.currentUrl,
      constValues.clientStatusCodes.DashboardPage,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString,
      constValues.emptyString
    );
  }
  async componentDidMount() {
    this.getDashboards(this.state.menuClicked, this.state.menuhierarchy);
  }

  handleSubmit = (dashboardurl, dashbaoardtype) => (event) => {
    if (dashbaoardtype == constValues.TableauDashboard)
      window.location.href = "/DashBoardDisplay?query=" + dashboardurl; // Tableau dashboard
    else
      window.location.href = "/PBIDashboard?query="+dashboardurl; // PowerBI dashboard
  };

  //download the help guide
  async downloadHelpguide(filePath) {
    const { userEmailAddress, accessGroup } = await authValues.getAuthValues();
    const sesssionDetails = await Auth.currentSession();
    let accessToken = sesssionDetails.getAccessToken().getJwtToken();
    axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

    //complete file path for constructing the presigned url
    let reqParams = {
      filePath: filePath,
      accessGroup: accessGroup,
      userEmailAddress: userEmailAddress,
    };
    const res = await http.post(
      http.apiEndPoint + "downloadgenericfiles",
      reqParams
    );

    if (res.data == configValues.errorResponse) {
      alert(configValues.fileDownloadError);
    } else {
      window.open(res.data);
    }
  }

  //render the help document icon
  renderHelpDocumentDisplay(filePath) {
    if (filePath) {
      return (
        <i
          className="fa fa-info float-right p-1 my-1"
          title="Help Document"
          data-placement="top"
          data-toggle="tooltip"
          onClick={async () => {
            await this.downloadHelpguide(filePath);
          }}
        ></i>
      );
    } else {
      //to maintain the length of the div boxhide the icon
      return (
        <i
          className="fa fa-download float-right p-1 my-1 fa-hide"
          title=""
          data-placement="top"
          data-toggle="tooltip"
        ></i>
      );
    }
  }

  //Display errorPage or dashboards
  displayDashboards() {
    if (this.state.dashboards.status == configValues.errorResponse) {
      return (
        <React.Fragment>
          <ErrorPage />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <br />
          <div className="container">
            {/* <h4>{this.state.dashboards[0].menu}</h4> */}
            <h4>{ this.props.location.state}</h4>
            <div className="row">
              {this.state.dashboards.map((dashboard) => (
                <div className="col-md-4" key={dashboard.name}>
                  <div className="card mb-4 box-shadow">
                    {" "}
                    <img
                      onClick={this.handleSubmit(dashboard.appid, dashboard.apptype)}
                      className="card-img-top"
                      src={dashboard.image}
                      alt="Card image cap"
                      id="cardhover"
                    />
                    {/* </Link> */}
                    <div className="card-body">
                      <span className="card-text">{dashboard.title}</span>
                      {this.renderHelpDocumentDisplay(
                        dashboard.helpdoclocation
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* </div> */}
        </React.Fragment>
      );
    }
  }

  render() {
    if (this.state.loading) return <Loading />;
    return <React.Fragment>{this.displayDashboards()}</React.Fragment>;
  }
}

export default DigitalDashboards;
